/*Color variables*/
/*Gradient variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
a {
    color: #2a2635;
}
a:hover {
    color: #2a2635;
}
.bg-primary {
    background: #2a2635 !important;
}
a.bg-primary:hover,
a.bg-primary:focus {
    background-color: #5a51c7 !important;
}
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #5a51c7 !important;
}
/*--- gradient-backgrounds --*/
.bg-primary-gradient {
    background: linear-gradient(
        to bottom right,
        #9e88f5 0%,
        #2a2635 100%
    ) !important;
}
a.bg-primary-gradient:hover,
a.bg-primary-gradient:focus {
    background-color: #2a2635 !important;
}
button.bg-primary-gradient:hover,
button.bg-primary-gradient:focus {
    background-color: #2a2635 !important;
}
.border-primary {
    border-color: #8580c1 !important;
}
.text-primary {
    color: #2a2635 !important;
}
a.text-primary:hover,
a.text-primary:focus {
    color: #4e12b6 !important;
}
.table-primary {
    background-color: #d2cdf9;
}
.table-primary > th,
.table-primary > td {
    background-color: #d2cdf9;
}
.table-hover .table-primary:hover {
    background-color: #b7cded;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #b7cded;
}
.select2-container--default.select2-container--focus
    .select2-selection--multiple {
    border-color: #2a2635;
}
.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: #2a2635 !important;
    border: 1px solid #2a2635 !important;
}
.btn-primary {
    color: #fff !important;
    background: #2a2635 !important;
    border-color: #6a62cc !important;
}
.btn-primary:hover {
    color: #fff;
    background-color: #5a51c7;
    border-color: #5a51c7;
}
.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}
.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #5a51c7;
    border-color: #5a51c7;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #5a51c7;
    border-color: #5a51c7;
}
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5a51c7;
    border-color: #5a51c7;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}
.btn-primary-light {
    color: #2a2635;
    background: #d6d2fb !important;
    border-color: #d6d2fb !important;
}
.btn-primary-light:hover {
    color: #fff;
    background-color: #5a51c7 !important;
    border-color: #5a51c7;
}
.btn-primary-light:focus,
.btn-primary-light.focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}
.btn-primary-gradient {
    color: #fff;
    background: linear-gradient(
        to bottom right,
        #9e88f5 0%,
        #2a2635 100%
    ) !important;
    border-color: #2a2635 !important;
    box-shadow: 0 5px 10px rgba(98, 89, 202, 0.3);
}
.btn-primary-gradient:hover {
    color: #fff;
    background-color: #2a2635;
    border-color: #2a2635;
}
.btn-primary-gradient:focus,
.btn-primary-gradient.focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}
.btn-primary-gradient.disabled,
.btn-primary-gradient:disabled {
    color: #fff;
    background-color: #2a2635;
    border-color: #2a2635;
}
.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #2a2635;
    border-color: #2a2635;
}
.show > .btn-primary-gradient.dropdown-toggle {
    color: #fff;
    background-color: #2a2635;
    border-color: #2a2635;
}
.btn-primary-gradient:not(:disabled):not(.disabled):active:focus,
.btn-primary-gradient:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}
.show > .btn-primary-gradient.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}
.btn-outline-primary {
    color: #2a2635;
    background: transparent;
    background-image: none;
    border-color: #2a2635;
}
.btn-outline-primary:hover {
    color: #fff;
    background: #5a51c7;
    border-color: #5a51c7;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.1);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #2a2635;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #2a2635;
    border-color: #2a2635;
}
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2a2635;
    border-color: #2a2635;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.1);
}
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.1);
}
.btn-link {
    color: #2a2635;
}
.btn-link:hover {
    color: #5a51c7;
}
.drop-icon-wrap .drop-icon-item:hover {
    color: #2a2635;
}
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item.active,
.dropdown-item:active {
    color: #2a2635;
}
.timeline__item:after {
    border: 6px solid #2a2635;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #2a2635;
    border-color: #2a2635;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::before,
.custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: #2a2635;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #2a2635;
    border-color: #2a2635;
}
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: #2a2635;
}
.form-select:focus,
.form-file-input:focus ~ .form-file-label::after {
    border-color: none;
}
.form-file-label::after {
    background-color: #2a2635;
}
.custom-range::-webkit-slider-thumb,
.custom-range::-moz-range-thumb,
.custom-range::-ms-thumb {
    background-color: #2a2635;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: #2a2635;
}
.page-link:hover {
    color: #5a51c7;
}
.page-item.active .page-link {
    color: #fff;
    background-color: #2a2635;
    border-color: #2a2635;
}
.range.range-primary input[type='range']::-webkit-slider-thumb,
.range.range-primary input[type='range']::-moz-slider-thumb {
    background-color: #2a2635;
}
.range.range-primary output {
    background-color: #2a2635;
}
.range.range-primary input[type='range'] {
    outline-color: #2a2635;
}
.panel.price > .panel-heading {
    background: #2a2635;
}
.nav-tabs .nav-item1 .nav-link:hover:not(.disabled),
.nav-tabs .nav-item1 .nav-link.active {
    background: #2a2635;
}
.pop-primary {
    color: #2a2635;
}
.heading-primary {
    background-color: #2a2635;
    color: #ffffff;
    padding: 5px;
}
.breadcrumb-item.active {
    color: #f5f3f9;
}
.breadcrumb-item1 a {
    color: #495584;
}
.breadcrumb-item2 a {
    color: #495584;
}
.panel-title1 a {
    background: #f2f1f9 !important;
}
.btn.dropdown-toggle.btn-primary ~ .dropdown-menu .dropdown-plus-title {
    border-color: #6259ca !important;
}
.panel-default > .panel-heading {
    color: #2a2635;
}
.alert-primary {
    color: #2a2635;
    background-color: rgba(98, 89, 202, 0.1);
    border-color: rgba(98, 89, 202, 0.01);
}
.alert-primary hr {
    border-top-color: #2a2635;
}
.alert-primary .alert-link {
    color: #2a2635;
}
.list-group-item-primary {
    color: #2a2635;
    background-color: #cbdbf2;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #2a2635;
    background-color: #b7cded;
}
.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #2a2635;
    border-color: #2a2635;
}
.header-bg {
    background: #2a2635;
    /* fallback for old browsers */
    background: -webkit-
        linear-gradient(to bottom right, #9e88f5 0%, #2a2635 100%) !important;
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to bottom right,
        #9e88f5 0%,
        #2a2635 100%
    ) !important;
}
.footer a:not(.btn) {
    color: #2a2635;
}
.nav-tabs .nav-link:hover:not(.disabled),
.nav-tabs .nav-link.active {
    background: #2a2635;
}
@media (max-width: 992px) {
    .nav-item.with-sub .sub-item {
        border-top: 2px solid #2a2635;
    }
}
.expanel-primary > .expanel-heading {
    color: #fff !important;
    background-color: #2a2635 !important;
    border-color: #2a2635 !important;
}
.login-img {
    background: #2a2635;
}
.avatar {
    background: #e3e1f5 no-repeat center/cover;
}
.spinner,
.spinner-lg,
.double-bounce1,
.double-bounce2,
.cube1,
.cube2 {
    background-color: #2a2635;
}
.lds-heart div {
    background: #2a2635;
}
.lds-heart div:after,
.lds-heart div:before {
    background: #2a2635;
}
.lds-ring div {
    border: 6px solid #2a2635;
    border-color: #2a2635 transparent transparent transparent;
}
.lds-hourglass:after {
    border: 26px solid #2a2635;
    border-color: #2a2635 transparent;
}
a.chip:hover {
    background-color: #2a2635;
}
.tag-primary {
    background-color: #2a2635;
    color: #fff;
}
.selectgroup-input:checked + .selectgroup-button {
    border-color: #2a2635;
    z-index: 1;
    color: #2a2635;
    background: #f6f4fb;
}
.selectgroup-input:focus + .selectgroup-button {
    border-color: #2a2635;
    z-index: 2;
    color: #2a2635;
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.25);
}
.custom-switch-input:checked ~ .custom-switch-indicator {
    background: #2a2635;
}
.ui-datepicker .ui-datepicker-title {
    color: #2a2635;
}
.timeline--horizontal .timeline-divider {
    background: #2a2635 !important;
}
#back-to-top {
    background-image: linear-gradient(
        to bottom right,
        #9e88f5 0%,
        #2a2635 100%
    );
}
#back-to-top:hover {
    color: #2a2635 !important;
    border: 2px solid #2a2635;
}
.tabs-menu1 ul li .active {
    border-bottom: 3px solid #2a2635;
}
.tab-content i,
.tabs-menu2 ul li .active {
    color: #2a2635;
}
.tab-content .btn i {
    color: #fff;
}
.rating-stars .rating-stars-container .rating-star.is--active .fa-heart,
.rating-stars .rating-stars-container .rating-star.is--hover .fa-heart {
    color: #2a2635;
}
.message-feed:not(.right) .mf-content {
    background: #2a2635;
    color: #fff;
}
.message-feed:not(.right) .mf-content:before {
    border-right-color: #2a2635;
}
.msb-reply button {
    background: #2a2635;
}
/* --------Added--------- css*/
.wizard-card .moving-tab {
    margin-top: 5px;
    background: linear-gradient(to bottom right, #9e88f5 0%, #2a2635 100%);
}
.cal1 .clndr .clndr-table .header-days {
    background: #fff;
}
.cal1 .clndr .clndr-table tr .day.today.event,
.cal1 .clndr .clndr-table tr .day.my-today.event {
    background: #2a2635;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button {
    color: #fff;
    background: #2a2635;
    border-radius: 5px;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button {
    color: #fff;
    background: #2a2635;
    border-radius: 5px;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button:hover {
    background: #2a2635;
}
.cal1
    .clndr
    .clndr-controls
    .clndr-control-button
    .clndr-previous-button:hover {
    background: #2a2635;
}
.fc button {
    background: #2a2635;
}
.fc-event {
    border: 1px solid #2a2635;
    background-color: #2a2635;
}
.fc-event-dot {
    background-color: #2a2635;
}
/* ----Added css----*/
div.conv-form-wrapper div.options div.option {
    color: #2a2635;
    border: 1px solid #2a2635;
}
form.convFormDynamic button.submit {
    color: #2a2635;
    border: 1px solid #2a2635 !important;
}
div.conv-form-wrapper div.options div.option.selected {
    background: #2a2635;
    color: #fff;
}
div.conv-form-wrapper div.options div.option.selected:hover {
    background: #2a2635;
}
form.convFormDynamic button.submit:hover {
    background: #2a2635 !important;
    color: #fff;
}
div.conv-form-wrapper div#messages div.message.from {
    background: #2a2635;
}
.addui-slider .addui-slider-track .addui-slider-handle:after,
.addui-slider .addui-slider-track .addui-slider-range {
    background: #2a2635;
}
.timeline__item:after {
    border: 6px solid #2a2635;
}
.accordionjs .acc_section.acc_active > .acc_head {
    background: #f2f1f9;
    color: #2a2635 !important;
}
.tab_wrapper > ul li.active {
    border-color: #2a2635;
    background: #2a2635;
}
.tab_wrapper.right_side > ul li.active:after {
    background: #2a2635;
}
.cal1 .clndr .clndr-table tr .day.today,
.cal1 .clndr .clndr-table tr .day.my-today {
    background: #fff;
    color: #343a40;
}
.cal1 .clndr .clndr-table tr .day.today:hover,
.cal1 .clndr .clndr-table tr .day.my-today:hover {
    background: #5a51c7;
    color: #fff;
}
.weather-card .top {
    background: #2a2635;
}
.primary .pricing-divider {
    background: #2a2635 !important;
}
.product-grid6 .title a:hover {
    color: #2a2635;
}
.product-grid6 .icons li a {
    color: #6e84a3;
    border: 1px solid #eaedf1;
}
.product-grid6 .icons li a:hover {
    color: #fff;
    background-image: linear-gradient(
        to bottom right,
        #9e88f5 0%,
        #2a2635 100%
    );
}
.product-grid6 .icons li a:after,
.product-grid6 .icons li a:before {
    background-image: linear-gradient(
        to bottom right,
        #9e88f5 0%,
        #2a2635 100%
    );
}
.card-counter.primary {
    background-image: linear-gradient(
        to bottom right,
        #9e88f5 0%,
        #2a2635 100%
    );
    color: #fff;
}
#user-profile .profile-details a > i,
#user-profile .profile-details i {
    color: #2a2635;
}
@media (max-width: 767.98px) and (min-width: 576px) {
    .search-show .search-element {
        background-image: linear-gradient(
            to bottom right,
            #9e88f5 0%,
            #2a2635 100%
        );
    }
}
@media (max-width: 575.98px) {
    .search-show .search-element {
        background-image: linear-gradient(
            to bottom right,
            #9e88f5 0%,
            #2a2635 100%
        );
    }
}
.text-primary-gradient {
    background: linear-gradient(to bottom right, #9e88f5 0%, #2a2635 100%);
}
.chat .msg_head,
.msb-reply button {
    background: #2a2635;
}
.top-footer p:hover {
    color: #2a2635;
}
.top-footer a address:hover,
.top-footer a:hover {
    color: #2a2635;
}
.footer-payments a:hover {
    color: #2a2635;
}
.footer-social-list a {
    color: #2a2635;
}
.footer-social-list a:hover {
    color: #2a2635;
}
.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
    color: #2a2635;
}
.custom-switch-input:checked ~ .custom-switch-indicator {
    background: #2a2635;
}
.label-primary {
    background: #2a2635;
    color: #fff;
}
.bg-primary-transparent {
    background-color: rgba(98, 89, 202, 0.1);
}
.text-primary-shadow {
    text-shadow: 0 5px 10px rgba(98, 89, 202, 0.3);
}
.chart-dropshadow-primary {
    -webkit-filter: drop-shadow(-6px 12px 4px rgba(98, 89, 202, 0.2));
    filter: drop-shadow(-6px 12px 4px rgba(98, 89, 202, 0.2));
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
    background: #544bb9;
}
/*-- Sidemenu --*/
.side-menu__item.active {
    color: #ffffff;
}
.side-menu__item.active .side-menu__icon {
    color: #ffffff !important;
}
/*-- Horizontal-menu --*/
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active,
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #2a2635;
}
.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover,
.horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a:hover {
    color: #2a2635;
}
.horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .link-list
    li
    a:hover {
    color: #2a2635;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #2a2635;
    border-color: #2a2635;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    border-color: #2a2635;
}
.onoffswitch2-checkbox:checked + .onoffswitch2-label {
    background-color: #2a2635;
}
.onoffswitch2-checkbox:checked + .onoffswitch-label2,
.onoffswitch2-checkbox:checked + .onoffswitch2-label:before {
    border-color: #2a2635;
}
/*--Dark-mode---*/
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active,
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #2a2635;
}
.breadcrumb-item.active,
.dropdown-item:hover {
    color: #827ae0;
}
.alert-primary {
    color: #827ae0;
}
.dropdown-item:focus,
.dropdown-item.active,
.dropdown-item:active {
    color: #8061ce;
}
.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover,
.horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a:hover {
    color: #827ae0;
}
.horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .link-list
    li
    a:hover {
    color: #827ae0;
}
.side-menu__item.active,
.side-menu__item:hover,
.side-menu__item:focus,
.side-menu__item.active .side-menu__icon {
    color: #dedefd !important;
}
.side-menu__item.active {
    color: #ffffff !important;
}
.side-menu__item:hover,
.side-menu__item:focus {
    color: #827ae0;
}
.dark-mode.dark-menu .side-menu__item.active .side-menu__icon,
.dark-mode.dark-menu .side-menu__item:hover .side-menu__icon,
.dark-mode.dark-menu .side-menu__item:focus .side-menu__icon {
    color: #827ae0;
}
.footer a:not(.btn) {
    color: #827ae0;
}
.text-primary {
    color: #827ae0 !important;
}
a.text-primary:hover,
a.text-primary:focus {
    color: #746ae8 !important;
}
.panel-default > .panel-heading,
.panel-tabs a {
    color: #ffffff;
}
.sidebar-icon {
    color: #827ae0 !important;
}
.side-menu__item.active {
    color: #ffffff;
}
.side-menu__item.active .side-menu__icon {
    color: #ffffff;
}
/*Color-Header*/
.color-hor-header .header.hor-header {
    background: #2a2635;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.light-hor-menu
    .hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > a.active,
.light-hor-menu
    .hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > a:hover {
    color: #fff;
    background: #2a2635;
}
.dark-hor-menu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active,
.dark-hor-menu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #fff;
    background: #2a2635;
}
.light-menu.side-menu__item.active .side-menu__icon,
.light-menu.side-menu__item:hover .side-menu__icon,
.light-menu.side-menu__item:focus .side-menu__icon,
.light-menu.side-menu__item.active,
.light-menu.side-menu__item:hover,
.light-menu.side-menu__item:focus {
    color: #8061ce;
}
.light-menu .side-menu__item.active .side-menu__icon {
    color: #fff;
}
.light-menu .side-menu__item:hover .side-menu__icon,
.light-menu .side-menu__item:focus .side-menu__icon,
.light-menu .side-menu__item.active,
.light-menu .side-menu__item:hover,
.light-menu .side-menu__item:focus {
    color: #2a2635 !important;
}
.default-body .app-sidebar {
    background: #2a2635 !important;
}
.transparent-mode .side-menu__item.active .side-menu__icon,
.transparent-mode .side-menu__item:hover .side-menu__icon,
.transparent-mode .side-menu__item:focus .side-menu__icon,
.transparent-mode .side-menu__item.active,
.transparent-mode .side-menu__item:hover,
.transparent-mode .side-menu__item:focus {
    color: #2a2635;
}
/*Color-Header*/
.color-hor-header .header.hor-header {
    background: #2a2635;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.horizontal-main2.horizontal-main {
    background: #2a2635;
}
@media only screen and (max-width: 991px) {
    .horizontalMenu > .horizontalMenu-list {
        background: #2a2635;
    }
}
.dark-menu .side-menu__item.active .side-menu__icon,
.dark-menu .side-menu__item:hover .side-menu__icon,
.dark-menu .side-menu__item:focus .side-menu__icon,
.dark-menu .side-menu__item.active,
.dark-menu .side-menu__item:hover {
    color: #8c63f3;
}
.color-menu .app-sidebar {
    background: #2a2635 !important;
}
.color-menu .side-header {
    background: #2a2635;
    border-bottom: 1px solid #7d76c5;
}
.color-menu .side-menu__item.active .side-menu__icon,
.color-menu .side-menu__item:hover .side-menu__icon,
.color-menu .side-menu__item:focus .side-menu__icon,
.color-menu .side-menu__item.active,
.color-menu .side-menu__item:hover,
.color-menu .side-menu__item:focus {
    color: #fff !important;
}
.color-menu .side-menu h3 {
    color: #b3aee8 !important;
}
.color-menu .side-menu .side-menu__icon,
.color-menu .side-menu .side-menu__item {
    color: #cccef5 !important;
}
.color-menu .side-menu__item:hover,
.color-menu .side-menu__item:focus {
    color: #fff !important;
    background: #7772dc !important;
}
.color-menu .side-menu__item:hover .side-menu__icon,
.color-menu .side-menu__item:hover .side-menu__label,
.color-menu .side-menu__item:focus .side-menu__icon,
.color-menu .side-menu__item:focus .side-menu__label {
    color: #ffffff !important;
}
.color-menu .slide.is-expanded a {
    color: #cccef5 !important;
}
.color-menu .slide-item {
    color: #cccef5;
}
.color-menu .slide-menu li .slide-item:before {
    color: #cccef5;
}
.color-menu .side-menu__item {
    color: #cccef5;
}
.gradient-menu .side-menu__item.active .side-menu__icon,
.gradient-menu .side-menu__item:hover .side-menu__icon,
.gradient-menu .side-menu__item:focus .side-menu__icon,
.gradient-menu .side-menu__item.active,
.gradient-menu .side-menu__item:hover,
.gradient-menu .side-menu__item:focus {
    color: #ccc9ec !important;
}
.gradient-menu .side-menu__item:hover .side-menu__icon,
.gradient-menu .side-menu__item:hover .side-menu__label,
.gradient-menu .side-menu__item:focus .side-menu__icon,
.gradient-menu .side-menu__item:focus .side-menu__label {
    color: #ccc9ec;
}
.color-menu.sidemenu-bgimage .side-header:before,
.color-menu.sidemenu-bgimage .app-sidebar:before {
    background: rgba(98, 89, 202, 0.94);
}
.transparent-mode.color-hor-menu .horizontal-main.hor-menu,
.dark-mode.color-hor-menu .horizontal-main.hor-menu,
.light-mode.color-hor-menu .horizontal-main.hor-menu,
.color-hor-menu .horizontal-main.hor-menu {
    background: #2a2635;
}
@media (max-width: 767px) {
    .color-hor-header .mobile-header {
        background: #2a2635;
    }
    .color-menu .mobile-header {
        background: #2a2635 !important;
    }
}
@media only screen and (max-width: 991px) {
    .color-hor-menu .horizontalMenu > .horizontalMenu-list {
        background: #2a2635;
    }
    .color-hor-menu .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
        background-color: #3d357d;
    }
    .color-hor-menu .mega-menubg {
        background: #3d357d !important;
    }
    .color-hor-menu
        .horizontalMenu
        > .horizontalMenu-list
        > li
        > ul.sub-menu
        > li
        > ul.sub-menu {
        background-color: #393275;
    }
    .color-hor-menu
        .horizontalMenu
        > .horizontalMenu-list
        > li
        > .horizontal-megamenu
        .link-list
        li
        a {
        background-color: #3d357d !important;
    }
}
