.dropdown-select .dropdown-menu {
    width: 118px !important;
    box-shadow: 0px 8px 14.72px 1.28px #2a2635;
    max-height: 200px;
    background-color: #484856 !important;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }


  button.dropdown-select {
    color: #77778e;
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: transparent;
    background-color: #32323e !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    font-size: 12px;
    min-width: 118px;
    height: 35px;
    border-radius: 5px;
  }
  
  .dropdown-select .dropdown-item {
    font-size: 12px;
    background-color: #484856;
  }