.header-nav {
  position: relative;
  z-index: 999;
  padding: 11px 30px 0;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
}
.nav-left {
  display: flex;
  align-items: center;
}
.nav-right {
  display: flex;
  align-items: center;
}
.logo {
  font-size: 32px;
  font-size: 50px;
  margin-top: -10px;
}
.search {
  padding: 5px;
  width: 300px;
}
.pt-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #52e3c2;
  border-radius: 16px;
  width: 32px;
  height: 32px;
}
.MenuLinks-Container ul li a {
    color: #ffff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    position: relative;
    padding: 10px 0;
}
.line-height-32 {
  line-height: 32px;
}
.suggestion-wrap {
  z-index: 9999 !important;
}
.suggestion-row {
  padding: 10px;
  display: flex;
  border-bottom: 1px solid black;
  cursor: pointer;
}
.flex1{
  flex: 1;
}
.suggestion-row button{
  background: #52e3c2;
  cursor: pointer;
  border-radius: 2px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px 1px 4px;
  transition: all ease-in-out 0.5s;
}
.suggestion-row button:hover{
  opacity: 0.8;
}
.suggst-bookmark svg:hover {
  fill: red;
  cursor: pointer;
}
.trial-suggestion {
  color: rgba(255,255,255,0.5);
  border-bottom: 1px solid black;
  cursor: not-allowed !important; 
}

@media only screen and (max-width: 470px) {
  .nav-right {
    margin-top: -50px;
  }
}
@media only screen and (max-width: 1225px) {
  .nav-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  .nav-right {
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
  }
  .header-nav {
    padding: initial;
  }
}
@media (max-width: 960px) {
  .logo{
    display: none;
  }
}