body {
    color: #dedefd;
    background-color: #22223d;
}
body*::-webkit-scrollbar-thumb,
body*:hover::-webkit-scrollbar-thumb {
    background: #272740;
}
caption {
    color: #505662;
}
.text-muted {
    color: #77778e !important;
}
hr {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.list-unstyled li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.blockquote-footer {
    color: #77778e;
}
.img-thumbnail {
    background-color: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.figure-caption {
    color: #77778e;
}
kbd {
    color: #fff;
    background-color: #343a40;
}
pre {
    color: #83839e;
}
.bg-transparent {
    background-color: transparent !important;
}
.border {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-right {
    border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-left {
    border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
blockquote {
    color: #77778e;
    border-left: 2px solid rgba(255, 255, 255, 0.1);
}
code {
    background: transparent;
    border: 1px solid transparent;
}
pre {
    color: #77778e;
    background-color: #2e2e4a;
    text-shadow: 0 1px #2e2e4a;
}
.section-nav {
    background-color: #f8f9fa;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.border {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.table thead th,
.text-wrap table thead th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.table tbody + tbody,
.text-wrap table tbody + tbody {
    border-top: 2px solid rgba(255, 255, 255, 0.1);
}
.table .table,
.text-wrap table .table,
.table .text-wrap table {
    background-color: #2e2e4a;
}
.text-wrap .table table,
.text-wrap table table {
    background-color: #2e2e4a;
}
.table-bordered {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
#external-events {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.fc-theme-standard td,
.fc-theme-standard th {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.fc-theme-standard .fc-scrollgrid {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.fc .fc-daygrid-day-number {
    color: #dedefd;
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: #2f2f46;
}
.fc-theme-standard .fc-list-day-cushion {
    background-color: #2f2f46;
}
.fc-theme-standard .fc-list {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.fc .fc-list-event:hover td {
    background-color: transparent;
    color: #6c5ffc;
}
.fc .fc-list-empty {
    background-color: #2a2635;
}
.badge.bg-white {
    background-color: #fff !important;
}
@media print {
    .table td,
    .text-wrap table td,
    .table th,
    .text-wrap table th {
        background-color: #fff !important;
    }
    .table-bordered th,
    .text-wrap table th,
    .table-bordered td,
    .text-wrap table td {
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }
}
.text-wrap table {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.table-bordered th,
.text-wrap table th,
.table-bordered td,
.text-wrap table td {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.1);
    color: inherit;
}
.table-hover tbody tr:hover,
.table-hover tbody th {
    background-color: #2e2e4a;
}
.table-active {
    background-color: rgba(0, 0, 0, 0.04);
}
.table-active > th,
.table-active > td {
    background-color: rgba(0, 0, 0, 0.04);
}
.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.04);
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
}
.table.dataTable thead th {
    border-bottom: 0 !important;
}
.form-control {
    color: #dedefd !important;
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.authentication .card:hover {
    box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.4), 0 3px 6px 0 rgba(0, 0, 0, 0.4);
}
.authentication input::placeholder {
    color: #77778e;
}
.authentication .flex-c-m .login100-social-item i {
    color: #fff;
}
.login100-social-item {
    color: #fff;
}
.login100-social-item:hover {
    color: #fff;
}
.select2-container--default .select2-selection--single {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.select2-container--default.select2-container--focus
    .select2-selection--multiple {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.select2-container--default .select2-selection--multiple {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    background: #2e2e4a;
}
.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice,
.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove {
    color: #fff !important;
}
.select2-container--default .select2-results > .select2-results__options {
    box-shadow: 0px 8px 14.72px 1.28px #2a2635;
}
.form-control {
    color: #99c1b2;
    opacity: 1;
}
.form-control:focus {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.form-control::-webkit-input-placeholder,
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
    color: #9999bf;
    opacity: 0.6;
}
.form-control::placeholder {
    color: #9999bf;
    opacity: 0.6;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #2d2d48;
}
.select2-container--default
    .select2-selection--single
    .select2-selection__placeholder {
    color: #9999bf;
    opacity: 0.6;
}
.select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #77778e;
}
.select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 2.375rem !important;
}
select.form-control:focus::-ms-value {
    color: #77778e;
    background-color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.form-control-file {
    display: block;
    width: 100%;
}
@media (max-width: 575.98px) {
    .countdown-timer-wrapper .timer .timer-wrapper .time {
        color: #fff;
        background: linear-gradient(
            to bottom right,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1)
        );
    }
}
.form-control-range {
    display: block;
    width: 100%;
}
.form-control-plaintext {
    color: #77778e;
}
.form-check-input:disabled ~ .form-check-label {
    color: #505662;
}
.valid-feedback {
    color: #09ad95;
}
.valid-tooltip {
    color: #fff;
    background-color: rgba(94, 186, 0, 0.8);
}
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #09ad95;
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #09ad95;
    box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label,
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
    color: #09ad95;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #09ad95;
}
.was-validated
    .custom-control-input:valid:checked
    ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #09ad95;
}
.was-validated
    .custom-control-input:valid:focus
    ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #2e2e4a, 0 0 0 2px rgba(94, 186, 0, 0.25);
}
.was-validated .form-file-input:valid ~ .form-file-label,
.form-file-input.is-valid ~ .form-file-label {
    border-color: #09ad95;
}
.was-validated .form-file-input:valid:focus ~ .form-file-label,
.form-file-input.is-valid:focus ~ .form-file-label {
    box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}
.invalid-feedback {
    color: #f82649;
}
.invalid-tooltip {
    color: #fff;
    background-color: rgba(205, 32, 31, 0.8);
}
.was-validated .form-control:invalid {
    border-color: #f82649;
}
.form-control.is-invalid {
    border-color: #f82649;
}
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #f82649;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #f82649;
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
    color: #f82649 !important;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #f82649;
    border-color: #e62b4a;
}
.was-validated
    .custom-control-input:invalid:checked
    ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #e23e3d;
}
.was-validated
    .custom-control-input:invalid:focus
    ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #2e2e4a, 0 0 0 2px rgba(205, 32, 31, 0.25);
}
.was-validated .form-file-input:invalid ~ .form-file-label,
.form-file-input.is-invalid ~ .form-file-label {
    border-color: #f82649;
}
.was-validated .form-file-input:invalid:focus ~ .form-file-label,
.form-file-input.is-invalid:focus ~ .form-file-label {
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}
.collapse:not(.show) {
    background: #fff;
}
.dropdown-menu {
    color: #77778e;
    background-color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.dropdown-divider {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.notifyimg {
    color: #fff;
}
.drop-icon-wrap .drop-icon-item {
    color: #878787;
}
.drop-icon-wrap .drop-icon-item:hover {
    background-color: #2e2e4a;
}
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item.active,
.dropdown-item:active {
    background-color: #2e2e4a;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #fff;
    background-color: transparent;
}
.dropdown-menu.show {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.header-search-icon .dropdown-menu {
    box-shadow: 0 10px 15px rgba(42, 38, 53, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.dropdown-header {
    color: #77778e;
}
.dropdown-item-text {
    color: #83839e;
}
.input-group-text {
    color: #77778e;
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
#global-loader {
    background: #22223d;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
}
.custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: rgba(218, 201, 232, 0.5);
}
.custom-control-input:disabled ~ .custom-control-label {
    color: #77778e;
}
.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: rgba(255, 255, 255, 0.1);
}
.custom-control-label::before {
    background-color: rgba(255, 255, 255, 0.1);
}
.custom-checkbox
    .custom-control-input:disabled:indeterminate
    ~ .custom-control-label::before {
    background-color: rgba(212, 182, 228, 0.5);
}
.custom-select {
    color: #77778e;
    background: #2e2e4a
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E")
        no-repeat right 0.75rem center;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.custom-select:focus::-ms-value {
    color: #77778e;
    background-color: #fff;
}
.custom-select:disabled {
    color: #77778e;
    background-color: rgba(255, 255, 255, 0.1);
}
.form-file-input:focus ~ .form-file-label {
    border-color: rgba(255, 255, 255, 0.1);
}
.form-file-label {
    color: #77778e;
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.form-file-label::after {
    color: #fff;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.custom-range::-webkit-slider-thumb:focus {
    box-shadow: 0 0 0 1px #2e2e4a, 0 0 0 2px rgba(98, 58, 162, 0.25);
}
.custom-range::-webkit-slider-thumb:active {
    background-color: #d4e1f4;
}
.custom-range::-webkit-slider-runnable-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
}
.custom-range::-moz-range-thumb:focus {
    box-shadow: 0 0 0 1px #2e2e4a, 0 0 0 2px rgba(98, 58, 162, 0.25);
}
.custom-range::-moz-range-thumb:active {
    background-color: #d4e1f4;
}
.custom-range::-moz-range-track {
    background-color: rgba(255, 255, 255, 0.1);
}
.custom-range::-ms-thumb:focus {
    box-shadow: 0 0 0 1px #2e2e4a, 0 0 0 2px rgba(98, 58, 162, 0.25);
}
.custom-range::-ms-thumb:active {
    background-color: #d4e1f4;
}
.custom-range::-ms-fill-lower,
.custom-range::-ms-fill-upper {
    background-color: rgba(255, 255, 255, 0.1);
}
.nav-link.disabled {
    color: #4f4f67;
}
.nav-link.disable {
    color: #cba4f7;
}
.nav-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
}
.nav-tabs .nav-link.disabled {
    color: #77778e;
    background-color: transparent;
    border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
}
.navbar-dark .navbar-text a {
    color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}
.card {
    background-color: #2a2635;
    border: 1px solid transparent;
    box-shadow: 0 3px 9px 0 rgba(28, 28, 51, 0.15);
}
.card-header {
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.card-footer {
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.page-link {
    background-color: #484856;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #77778e;
}
.page-link:hover {
    background-color: rgba(70, 127, 207, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.page-item.active .page-link {
    color: #fff;
}
.page-item.disabled .page-link {
    color: #4f4f67;
    background-color: #2a2635;
    border-color: rgba(255, 255, 255, 0.1);
}
.mail-box .sm-side {
    background: none repeat scroll 0 0 #e5e8ef;
}
.mail-box .lg-side {
    background: none repeat scroll 0 0 #fff;
}
.mail-box .sm-side .user-head {
    background: none repeat scroll 0 0 #00a8b3;
    color: #fff;
}
.user-head .user-name h5 a {
    color: #fff;
}
.user-head .user-name span a {
    color: #87e2e7;
}
a.mail-dropdown {
    background: none repeat scroll 0 0 #80d3d9;
    color: #01a7b3;
}
.btn-compose {
    background: none repeat scroll 0 0 #ff6c60;
    color: #fff;
}
.btn-compose:hover {
    background: none repeat scroll 0 0 #f5675c;
    color: #fff;
}
.inbox-divider {
    border-bottom: 1px solid #d5d8df;
}
ul.inbox-nav li a {
    color: #6a6a6a;
}
ul.inbox-nav li a:hover {
    background: none repeat scroll 0 0 #d5d7de;
    color: #6a6a6a;
}
ul.inbox-nav li.active a {
    background: none repeat scroll 0 0 #d5d7de;
    color: #6a6a6a;
}
ul.inbox-nav li a:focus {
    background: none repeat scroll 0 0 #d5d7de;
    color: #6a6a6a;
}
ul.inbox-nav li a i {
    color: #6a6a6a;
}
ul.labels-info li h4 {
    color: #5c5c5e;
}
ul.labels-info li a {
    color: #6a6a6a;
}
ul.labels-info li a:hover,
ul.labels-info li a:focus {
    background: none repeat scroll 0 0 #d5d7de;
    color: #6a6a6a;
}
.nav.nav-pills.nav-stacked.labels-info p {
    color: #9d9f9e;
}
.inbox-head .sr-input {
    color: #8a8a8a;
}
.inbox-head .sr-btn {
    background: none repeat scroll 0 0 #1643a3;
    color: #fff;
}
.table-inbox {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.table-inbox tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.table-inbox tr td .fa-star {
    color: rgba(106, 221, 196, 0.06);
}
.table-inbox tr td .fa-star.inbox-started,
.table-inbox tr td .fa-star:hover {
    color: #f78a09;
}
.table-inbox tr.unread td {
    background: #2e2e4a;
}
.mail-option .chk-all,
.mail-option .btn-group a.btn {
    background: none repeat scroll 0 0 #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #dedefd;
}
.inbox-pagination a.np-btn {
    background: none repeat scroll 0 0 #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #dedefd;
}
.fileinput-button {
    background: none repeat scroll 0 0 #eeeeee;
    border: 1px solid #e6e6e6;
}
.inbox-body .modal .modal-body input,
.inbox-body .modal .modal-body textarea {
    border: 1px solid #e6e6e6;
}
.btn-send {
    background: none repeat scroll 0 0 #00a8b3;
    color: #fff;
}
.btn-send:hover {
    background: none repeat scroll 0 0 #00a8b3;
    color: #fff;
    background: none repeat scroll 0 0 #009da7;
}
.heading-inbox h4 {
    border-bottom: 1px solid #ddd;
    color: #77778e;
}
.sender-dropdown {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0.1);
    color: #777;
}
.view-mail a {
    color: #ff6c60;
}
.range {
    background-color: whitesmoke;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.range input[type='range']::-webkit-slider-thumb,
.range input[type='range']::-moz-slider-thumb {
    color: white;
    background-color: #999;
}
.range output {
    color: white;
    background-color: #999;
}
.pricing-divider {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}
.princing-item .card-footer {
    background: #2a2635;
}
.pricing {
    color: #fff;
}
.pricing1 {
    color: #7a7a92;
}
.pricing .card-category {
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
}
.pricing .list-unstyled li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.pricing1 .list-unstyled li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.pricing1 .card-category {
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
}
.pricing-table:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}
.pricing-table:hover > .panel-footer-landing {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) inset;
}
.pricing-table:hover > .panel > .panel-body-landing {
    background: #1643a3;
}
.pricing-table:hover > .panel > .panel-heading-landing-box {
    background: #fff !important;
    color: #333 !important;
}
.pricing-table:hover > .panel > .controle-header,
.pricing-table:hover > .panel > .panel-footer {
    background: #1643a3 !important;
}
.princing-item:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}
.btn-price:hover {
    background: #fff !important;
    color: #1643a3 !important;
}
.pricing-table:hover > .panel > .controle-header > .panel-title-landing {
    color: #fff !important;
}
.pricing-table > .panel > .controle-header > .panel-title-landing {
    color: #000 !important;
}
.panel-body-landing:hover > .table > tbody > tr > td {
    color: #fff !important;
}
.panel-heading-landing {
    background: #f7f7f7 !important;
    border: solid 2px #1643a3 !important;
}
.panel-heading-landing-box {
    background: #1643a3 !important;
    color: #fff !important;
}
.panel-title-landing {
    color: #1643a3 !important;
}
.panel-body-landing {
    border: solid 2px #1643a3 !important;
    background: #fff;
}
.panel-footer-landing {
    border: solid 2px #1643a3 !important;
    background: #f7f7f7;
}
.panel-heading {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: #fff;
}
.panel.price {
    box-shadow: 0 0.15rem 1.75rem 0 #0e0f2e;
    background: #2e2e4a;
}
.panel.price > .panel-heading {
    color: #fff;
}
.panel.price:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}
.price .panel-footer,
.price.panel-color > .panel-body {
    background-color: #2a2635;
}
.panel-footer {
    background-color: #2f2f4b;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.panel.price .btn {
    box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
}
#popup {
    background: #fe6b1f;
}
#copy {
    color: white;
}
.nav1 {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-item1:hover:not(.disabled),
.nav-item1.active {
    color: #fff;
}
.nav-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-tabs .nav-item1.nav-link {
    border: 1px solid transparent;
}
.nav-tabs .nav-item1 .nav-link {
    color: #77778e;
}
.nav-tabs .nav-item1 .nav-link:hover:not(.disabled),
.nav-tabs .nav-item1 .nav-link.active {
    color: #fff;
}
.nav-tabs .nav-submenu {
    background: #000;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.nav-tabs .nav-submenu .nav-item1 {
    color: #77778e;
}
.nav-tabs .nav-submenu .nav-item1.active {
    color: #467fcf;
}
.nav-tabs .nav-submenu .nav-item1:hover {
    color: #77778e;
    background: rgba(0, 0, 0, 0.024);
}
.tooltip-inner {
    color: #fff;
    background-color: #000;
}
.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before,
.tooltip.tooltip-top .tooltip-inner::before {
    border-top-color: #000;
}
.tooltip.bs-tether-element-attached-left .tooltip-inner::before,
.tooltip.tooltip-right .tooltip-inner::before {
    border-right-color: #000;
}
.tooltip.bs-tether-element-attached-top .tooltip-inner::before,
.tooltip.tooltip-bottom .tooltip-inner::before {
    border-bottom-color: #000;
}
.tooltip.bs-tether-element-attached-right .tooltip-inner::before,
.tooltip.tooltip-left .tooltip-inner::before {
    border-left-color: #000;
}
.heading-inverse {
    background-color: #232139;
    color: #ffffff;
}
.breadcrumb {
    color: #fff;
}
.breadcrumb-item a,
.breadcrumb-item + .breadcrumb-item::before {
    color: #77778e;
}
.breadcrumb-item1 a:hover {
    color: #123787;
}
.breadcrumb-item1 + .breadcrumb-item1::before {
    color: #77778e;
}
.breadcrumb-item1.active {
    color: #77778e;
}
.breadcrumb-item2 a:hover {
    color: #123787;
}
.breadcrumb-item2 + .breadcrumb-item2::before {
    color: #77778e;
}
.breadcrumb-item2.active {
    color: #77778e;
}
.panel-group .panel {
    border-color: rgba(255, 255, 255, 0.1);
}
.panel-default > .panel-heading {
    background-color: #2e2e4a;
    border-color: rgba(255, 255, 255, 0.1);
}
.more-less {
    color: #212121;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.accordionjs .acc_section .acc_head h3:before {
    color: #77778e;
}
.wrap {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.panel1 {
    border-color: #fff;
}
.panel-heading1 {
    background-color: #5797fc;
    color: #fff;
}
.panel-group1 .panel-body {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.panel-title1 a {
    color: #fff;
}
.panel-body1 {
    background: #fff;
}
.panel-title a.accordion-toggle:before,
.panel-title a.accordion-toggle.collapsed:before {
    color: #fff;
}
.btn.dropdown-toggle ~ .dropdown-menu,
ul.dropdown-menu li.dropdown ul.dropdown-menu {
    background-color: #77778e !important;
    border: 0 solid #2a2635 !important;
    box-shadow: 0 6px 12px rgba(34, 34, 61, 0.8) !important;
}
.btn.dropdown-toggle.btn-default ~ .dropdown-menu .dropdown-plus-title {
    border-color: rgba(255, 255, 255, 0.1) !important;
}
.dropdown-menu {
    background-color: #2a2635;
    -webkit-box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
    box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
}
.dropdown-menu > li > a {
    color: #dedefd;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    color: #dedefd;
    background-color: #2e2e4a;
}
.dropdown-menu .divider {
    background-color: rgba(255, 255, 255, 0.1);
}
.dropdown-menu .dropdown-plus-title {
    color: #2a2635 !important;
}
.dropdown-menu-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.dropdown-menu-header label {
    color: #77778e;
}
.dropdown-media-list .media:hover,
.dropdown-media-list .media:focus {
    background-color: #f8f9fa;
}
.dropdown-media-list .media-body > div p {
    color: #77778e;
}
.dropdown-media-list .media-body > div span {
    color: #cfcfcf;
}
.dropdown-media-list .media-body > p {
    color: #bfbfbf;
}
.dropdown-list-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.jumbotron {
    background-color: #25253e;
}
.progress {
    background-color: #25253e;
    box-shadow: 0 5px 10px rgba(42, 38, 53, 0.3);
}
.progress-bar {
    color: #fff;
}
.list-group-item.active {
    background-color: #f6f4fb;
    color: #25252a;
    border-color: rgba(255, 255, 255, 0.1);
}
.list-group-item-action {
    color: #77778e;
}
.list-group-item-action:hover {
    color: #2a2635;
    background-color: transparent;
}
.list-group-item-action:focus {
    color: #77778e;
    background-color: #2e2e4a;
}
.list-group-item-action:active {
    color: #77778e;
    background-color: rgba(255, 255, 255, 0.1);
}
.list-group-item,
.listorder,
.listorder1,
.listunorder,
.listunorder1 {
    background-color: #2a2635;
    color: #aaaad8;
}
.list-group-item:hover,
.listorder:hover,
.listorder1:hover,
.listunorder:hover,
.listunorder1:hover {
    color: #827ae0;
}
.tab_wrapper.right_side > ul li.active:before {
    background: transparent;
}
.alert-secondary {
    background: rgba(235, 111, 51, 0.1);
    border: rgba(235, 111, 51, 0.1);
}
.mail-inbox .icons {
    color: #dedefd !important;
}
.list-group-item.disabled,
.list-group-item:disabled {
    color: #4f4f67;
    background-color: #2a2635;
}
.list-group-item.active {
    background-color: #2e2e4a;
    color: #77778e;
}
.close {
    color: #77778e;
    text-shadow: 0 1px 0 #2e2e4a;
}
.close:hover,
.close:focus {
    color: #dedefd;
}
.modal-content {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}
.modal-backdrop {
    background-color: #000;
}
.modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.widget-info i {
    border: 2px solid #fff;
}
.widget-info a {
    border-bottom: 1px solid #fff;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before {
    border-top-color: #000;
}
.bs-tooltip-right .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before {
    border-right-color: #000;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before {
    border-bottom-color: #000;
}
.bs-tooltip-left .tooltip-arrow:before,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow:before {
    border-left-color: #000;
}
.tooltip-inner {
    color: #fff;
    background-color: #000;
}
.popover {
    background-color: #22223d;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.bs-popover-top .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='top'] .popover-arrow::before {
    border-top-color: rgba(255, 255, 255, 0.1);
}
.bs-popover-top .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top'] .popover-arrow::after {
    border-top-color: #22223d;
}
.bs-popover-end .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='right'] .popover-arrow::before {
    border-right-color: rgba(255, 255, 255, 0.1);
}
.bs-popover-end .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right'] .popover-arrow::after {
    border-right-color: #22223d;
}
.bs-popover-bottom .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::before {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}
.bs-popover-bottom .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::after {
    border-bottom-color: #22223d;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.bs-popover-start .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='left'] .popover-arrow::before {
    border-left-color: rgba(255, 255, 255, 0.1);
}
.bs-popover-start .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='left'] .popover-arrow::after {
    border-left-color: #22223d;
}
.popover-header {
    background-color: #22223d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
}
.popover-body {
    color: #77778e;
}
.carousel-control-prev,
.carousel-control-next {
    color: #fff;
}
.carousel-control-prev:hover,
.carousel-control-prev:focus {
    color: #fff;
}
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
}
.carousel-indicators li,
.carousel-indicators1 li,
.carousel-indicators2 li,
.carousel-indicators3 li,
.carousel-indicators4 li,
.carousel-indicators5 li {
    background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators .active,
.carousel-indicators1 .active,
.carousel-indicators2 .active,
.carousel-indicators3 .active,
.carousel-indicators4 .active,
.carousel-indicators5 .active {
    background-color: #fff;
}
.carousel-caption {
    color: #fff;
}
.page-title-icon {
    color: #77778e;
}
.page-subtitle {
    color: #c0a0f1;
}
.page-description {
    color: #77778e;
}
.aside {
    background: #ffffff;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
}
.aside-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.aside-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.header {
    background: #2a2635;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-unread {
    background: #ecd938;
}
.nav-link.icon i {
    color: #ffffff !important;
}
.footer {
    background: #2a2635;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer .social ul li a {
    color: #dedefd;
}
.text-default {
    color: #7a7a92 !important;
}
.text-muted-dark {
    color: #77778e !important;
}
a.icon:hover {
    color: #2a2635 !important;
}
.shadow {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}
.nav-tabs {
    color: #000;
}
.nav-tabs .nav-link {
    color: #77778e;
}
.nav-tabs .nav-link:hover:not(.disabled),
.nav-tabs .nav-link.active {
    color: #fff;
}
.dropdown-menu-arrow:before,
.dropdown-menu.header-search:before {
    background: #2e2e4a;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.nav-tabs .nav-submenu {
    background: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.nav-tabs .nav-submenu .nav-item {
    color: #77778e;
}
.nav-tabs .nav-submenu .nav-item.active {
    color: #467fcf;
}
.nav-tabs .nav-submenu .nav-item:hover {
    color: #77778e;
    background: rgba(0, 0, 0, 0.024);
}
.product_price .old_price {
    color: #93a3ba;
}
.expanel-default {
    border: #ddd !important;
}
.expanel {
    background-color: #2e2e4a !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
}
.expanel-default > .expanel-heading {
    background-color: #2e2e4a !important;
    border-color: #2e2e4a !important;
}
.expanel-heading {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.expanel-footer {
    background-color: #2e2e4a !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.thumbnail {
    background-color: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.table th,
.text-wrap table th {
    color: #dedefd;
}
.table-vcenter td,
.table-vcenter th {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.table-striped tbody tr:nth-of-type(even) {
    background-color: #2e2e4a;
}
.table-calendar-link {
    background: #f8f9fa;
    color: #77778e;
}
.table-calendar-link:before {
    background: #467fcf;
}
.table-calendar-link:hover {
    color: #fff;
    background: #467fcf;
}
.table-calendar-link:hover:before {
    background: #fff;
}
.table-header:hover,
.table-header-asc,
.table-header-desc {
    color: #77778e !important;
}
.page-breadcrumb .breadcrumb-item {
    color: #77778e;
}
.page-breadcrumb .breadcrumb-item.active {
    color: #77778e;
}
.pagination-simple .page-item.active .page-link,
.page-total-text {
    color: #77778e;
}
.card .card {
    box-shadow: 0px 0px 10px #22223d;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.card-body + .card-body {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.card-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: #77778e;
}
.card-img-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}
.reg {
    color: #2e1170;
}
.card-title small,
.card-subtitle {
    color: #77778e;
}
.card-body + .card-table {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.profile-img {
    border: 1px solid rgba(167, 180, 201, 0.2);
    background: rgba(225, 225, 225, 0.2);
}
.card-profile-img {
    border: 3px solid #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.card-body + .card-list-group {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.list-media .info .text-right {
    color: #8a8a8a;
}
.card-options {
    color: #77778e;
}
.card-options a:not(.btn) {
    color: #77778e;
}
.card-options a:not(.btn):hover {
    color: #77778e;
}
.widgets-cards .wrp.icon-circle i {
    color: #fff;
}
.card-map {
    background: rgba(255, 255, 255, 0.1);
}
.card-tabs-bottom .card-tabs-item.active {
    border-top-color: #fff;
}
a.card-tabs-item {
    background: #fafbfc;
}
a.card-tabs-item.active {
    background: #fff;
    border-bottom-color: #fff;
}
.card-category {
    background: #2e2e4a;
}
.popover {
    -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
}
.header .dropdown .nav-link.icon:hover {
    background: transparent !important;
}
.dropdown-item {
    color: #c1c1de;
}
.dropdown-icon {
    color: #dedefd;
}
.list-group-item .icon {
    color: #77778e !important;
}
.list-group-transparent .list-group-item.active {
    background: #343454;
    color: #dedefd;
}
.responsive-navbar.navbar .navbar-collapse {
    background: #2a2635;
    box-shadow: 0 12px 11px -3px #2a2635;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.responsive-navbar.navbar .nav-link.icon {
    color: #2a1b4d !important;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #2a2635;
    box-shadow: 0px 2px 3px #1a1a2f;
}
.avatar-status {
    border: 2px solid #2a2635;
}
.avatar-placeholder {
    background: #ced4da
        url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134"><path fill="#77778e" d="M65.92 66.34h2.16c14.802.42 30.928 6.062 29.283 20.35l-1.618 13.32c-.844 6.815-5.208 7.828-13.972 7.866H52.23c-8.764-.038-13.13-1.05-13.973-7.865l-1.62-13.32C34.994 72.4 51.12 66.76 65.92 66.34zM49.432 43.934c0-9.82 7.99-17.81 17.807-17.81 9.82 0 17.81 7.99 17.81 17.81 0 9.82-7.99 17.807-17.81 17.807-9.82 0-17.808-7.987-17.808-17.806z"/></svg>')
        no-repeat center / 80%;
}
.avatar-list-stacked .avatar {
    box-shadow: 0 0 0 2px #2a2635;
}
.mail-chats {
    border-top: 1px solid rgba(128, 128, 128, 0.16);
}
.user p.u-name {
    color: #dedefd;
}
.user p.u-designation {
    color: #77778e;
}
div.online-status .status.online {
    background: rgba(0, 128, 0, 0.68);
}
.online-status .status.offline {
    background: rgba(255, 0, 0, 0.7);
}
.icons-list-item {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.link-overlay-bg {
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
}
.custom-control-label:before {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: #2e2e4a;
}
.form-signin .form-control:focus {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.mediaicon {
    color: #fff;
}
.form-control.header-search {
    background: #2e2e4a;
    border: 1px solid rgba(225, 225, 225, 0.1);
    color: #fff;
}
.form-control.header-search::placeholder {
    color: #fff;
}
.form-control.header-search:hover,
.form-control.header-search:focus {
    border: 1px solid rgba(225, 225, 225, 0.1);
}
.input-icon-addon {
    color: #fff;
}
.input-icon-addon.search-icon {
    color: #636262 !important;
}
.form-fieldset {
    background: #f8f9fa;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.form-required {
    color: #f82649;
}
.form-help {
    color: #77778e;
    background: #2e2e4a;
}
.form-help:hover,
.form-help[aria-describedby] {
    background: #467fcf;
    color: #fff;
}
.jqstooltip {
    background: #333 !important;
}
.social-links li a {
    background: #f8f8f8;
    color: #77778e;
}
.chart-circle-value small,
.chart-circle-1-value small {
    color: #77778e;
}
.chip {
    color: #77778e;
    background-color: rgba(70, 127, 207, 0.06);
}
a.chip:hover {
    color: #fff;
}
.stamp {
    color: #fff;
    background: #77778e;
}
.chat-message {
    background-color: #467fcf;
    color: #fff;
}
.chat-message:after {
    border-bottom: 6px solid transparent;
    border-left: 6px solid #467fcf;
    border-top: 6px solid transparent;
}
.chat-line-friend .chat-message {
    background-color: #f3f3f3;
    color: #77778e;
}
.chat-line-friend .chat-message:after {
    border-right: 5px solid #f3f3f3;
}
.example {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.example-bg {
    background: #2e2e4a;
}
.highlight {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #2a2635;
    border-top: none;
}
.tag {
    color: #dedefd;
    background-color: #25253e;
}
a.tag:hover {
    background-color: rgba(110, 118, 135, 0.2);
}
.carousel-item-background {
    background: rgba(0, 0, 0, 0.5);
}
.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
    border-color: #467fcf;
    background-color: #467fcf;
}
.custom-range::-webkit-slider-runnable-track {
    background: #467fcf;
}
.custom-range::-webkit-slider-thumb {
    background: #fff;
    box-shadow: 1px 0 0 -6px rgba(0, 50, 126, 0.12),
        6px 0 0 -6px rgba(0, 50, 126, 0.12), 7px 0 0 -6px rgba(0, 50, 126, 0.12),
        8px 0 0 -6px rgba(0, 50, 126, 0.12), 9px 0 0 -6px rgba(0, 50, 126, 0.12),
        10px 0 0 -6px rgba(0, 50, 126, 0.12),
        11px 0 0 -6px rgba(0, 50, 126, 0.12),
        12px 0 0 -6px rgba(0, 50, 126, 0.12),
        13px 0 0 -6px rgba(0, 50, 126, 0.12),
        14px 0 0 -6px rgba(0, 50, 126, 0.12),
        15px 0 0 -6px rgba(0, 50, 126, 0.12),
        16px 0 0 -6px rgba(0, 50, 126, 0.12),
        17px 0 0 -6px rgba(0, 50, 126, 0.12),
        18px 0 0 -6px rgba(0, 50, 126, 0.12),
        19px 0 0 -6px rgba(0, 50, 126, 0.12),
        20px 0 0 -6px rgba(0, 50, 126, 0.12),
        21px 0 0 -6px rgba(0, 50, 126, 0.12),
        22px 0 0 -6px rgba(0, 50, 126, 0.12),
        23px 0 0 -6px rgba(0, 50, 126, 0.12),
        24px 0 0 -6px rgba(0, 50, 126, 0.12),
        25px 0 0 -6px rgba(0, 50, 126, 0.12),
        26px 0 0 -6px rgba(0, 50, 126, 0.12),
        27px 0 0 -6px rgba(0, 50, 126, 0.12),
        28px 0 0 -6px rgba(0, 50, 126, 0.12),
        29px 0 0 -6px rgba(0, 50, 126, 0.12),
        30px 0 0 -6px rgba(0, 50, 126, 0.12),
        31px 0 0 -6px rgba(0, 50, 126, 0.12),
        32px 0 0 -6px rgba(0, 50, 126, 0.12),
        33px 0 0 -6px rgba(0, 50, 126, 0.12),
        34px 0 0 -6px rgba(0, 50, 126, 0.12),
        35px 0 0 -6px rgba(0, 50, 126, 0.12),
        36px 0 0 -6px rgba(0, 50, 126, 0.12),
        37px 0 0 -6px rgba(0, 50, 126, 0.12),
        38px 0 0 -6px rgba(0, 50, 126, 0.12),
        39px 0 0 -6px rgba(0, 50, 126, 0.12),
        40px 0 0 -6px rgba(0, 50, 126, 0.12),
        41px 0 0 -6px rgba(0, 50, 126, 0.12),
        42px 0 0 -6px rgba(0, 50, 126, 0.12),
        43px 0 0 -6px rgba(0, 50, 126, 0.12),
        44px 0 0 -6px rgba(0, 50, 126, 0.12),
        45px 0 0 -6px rgba(0, 50, 126, 0.12),
        46px 0 0 -6px rgba(0, 50, 126, 0.12),
        47px 0 0 -6px rgba(0, 50, 126, 0.12),
        48px 0 0 -6px rgba(0, 50, 126, 0.12),
        49px 0 0 -6px rgba(0, 50, 126, 0.12),
        50px 0 0 -6px rgba(0, 50, 126, 0.12),
        51px 0 0 -6px rgba(0, 50, 126, 0.12),
        52px 0 0 -6px rgba(0, 50, 126, 0.12),
        53px 0 0 -6px rgba(0, 50, 126, 0.12),
        54px 0 0 -6px rgba(0, 50, 126, 0.12),
        55px 0 0 -6px rgba(0, 50, 126, 0.12),
        56px 0 0 -6px rgba(0, 50, 126, 0.12),
        57px 0 0 -6px rgba(0, 50, 126, 0.12),
        58px 0 0 -6px rgba(0, 50, 126, 0.12),
        59px 0 0 -6px rgba(0, 50, 126, 0.12),
        60px 0 0 -6px rgba(0, 50, 126, 0.12),
        61px 0 0 -6px rgba(0, 50, 126, 0.12),
        62px 0 0 -6px rgba(0, 50, 126, 0.12),
        63px 0 0 -6px rgba(0, 50, 126, 0.12),
        64px 0 0 -6px rgba(0, 50, 126, 0.12),
        65px 0 0 -6px rgba(0, 50, 126, 0.12),
        66px 0 0 -6px rgba(0, 50, 126, 0.12),
        67px 0 0 -6px rgba(0, 50, 126, 0.12),
        68px 0 0 -6px rgba(0, 50, 126, 0.12),
        69px 0 0 -6px rgba(0, 50, 126, 0.12),
        70px 0 0 -6px rgba(0, 50, 126, 0.12),
        71px 0 0 -6px rgba(0, 50, 126, 0.12),
        72px 0 0 -6px rgba(0, 50, 126, 0.12),
        73px 0 0 -6px rgba(0, 50, 126, 0.12),
        74px 0 0 -6px rgba(0, 50, 126, 0.12),
        75px 0 0 -6px rgba(0, 50, 126, 0.12),
        76px 0 0 -6px rgba(0, 50, 126, 0.12),
        77px 0 0 -6px rgba(0, 50, 126, 0.12),
        78px 0 0 -6px rgba(0, 50, 126, 0.12),
        79px 0 0 -6px rgba(0, 50, 126, 0.12),
        80px 0 0 -6px rgba(0, 50, 126, 0.12),
        81px 0 0 -6px rgba(0, 50, 126, 0.12),
        82px 0 0 -6px rgba(0, 50, 126, 0.12),
        83px 0 0 -6px rgba(0, 50, 126, 0.12),
        84px 0 0 -6px rgba(0, 50, 126, 0.12),
        85px 0 0 -6px rgba(0, 50, 126, 0.12),
        86px 0 0 -6px rgba(0, 50, 126, 0.12),
        87px 0 0 -6px rgba(0, 50, 126, 0.12),
        88px 0 0 -6px rgba(0, 50, 126, 0.12),
        89px 0 0 -6px rgba(0, 50, 126, 0.12),
        90px 0 0 -6px rgba(0, 50, 126, 0.12),
        91px 0 0 -6px rgba(0, 50, 126, 0.12),
        92px 0 0 -6px rgba(0, 50, 126, 0.12),
        93px 0 0 -6px rgba(0, 50, 126, 0.12),
        94px 0 0 -6px rgba(0, 50, 126, 0.12),
        95px 0 0 -6px rgba(0, 50, 126, 0.12),
        96px 0 0 -6px rgba(0, 50, 126, 0.12),
        97px 0 0 -6px rgba(0, 50, 126, 0.12),
        98px 0 0 -6px rgba(0, 50, 126, 0.12),
        99px 0 0 -6px rgba(0, 50, 126, 0.12),
        100px 0 0 -6px rgba(0, 50, 126, 0.12),
        101px 0 0 -6px rgba(0, 50, 126, 0.12),
        102px 0 0 -6px rgba(0, 50, 126, 0.12),
        103px 0 0 -6px rgba(0, 50, 126, 0.12),
        104px 0 0 -6px rgba(0, 50, 126, 0.12),
        105px 0 0 -6px rgba(0, 50, 126, 0.12),
        106px 0 0 -6px rgba(0, 50, 126, 0.12),
        107px 0 0 -6px rgba(0, 50, 126, 0.12),
        108px 0 0 -6px rgba(0, 50, 126, 0.12),
        109px 0 0 -6px rgba(0, 50, 126, 0.12),
        110px 0 0 -6px rgba(0, 50, 126, 0.12),
        111px 0 0 -6px rgba(0, 50, 126, 0.12),
        112px 0 0 -6px rgba(0, 50, 126, 0.12),
        113px 0 0 -6px rgba(0, 50, 126, 0.12),
        114px 0 0 -6px rgba(0, 50, 126, 0.12),
        115px 0 0 -6px rgba(0, 50, 126, 0.12),
        116px 0 0 -6px rgba(0, 50, 126, 0.12),
        117px 0 0 -6px rgba(0, 50, 126, 0.12),
        118px 0 0 -6px rgba(0, 50, 126, 0.12),
        119px 0 0 -6px rgba(0, 50, 126, 0.12),
        120px 0 0 -6px rgba(0, 50, 126, 0.12),
        121px 0 0 -6px rgba(0, 50, 126, 0.12),
        122px 0 0 -6px rgba(0, 50, 126, 0.12),
        123px 0 0 -6px rgba(0, 50, 126, 0.12),
        124px 0 0 -6px rgba(0, 50, 126, 0.12),
        125px 0 0 -6px rgba(0, 50, 126, 0.12),
        126px 0 0 -6px rgba(0, 50, 126, 0.12),
        127px 0 0 -6px rgba(0, 50, 126, 0.12),
        128px 0 0 -6px rgba(0, 50, 126, 0.12),
        129px 0 0 -6px rgba(0, 50, 126, 0.12),
        130px 0 0 -6px rgba(0, 50, 126, 0.12),
        131px 0 0 -6px rgba(0, 50, 126, 0.12),
        132px 0 0 -6px rgba(0, 50, 126, 0.12),
        133px 0 0 -6px rgba(0, 50, 126, 0.12),
        134px 0 0 -6px rgba(0, 50, 126, 0.12),
        135px 0 0 -6px rgba(0, 50, 126, 0.12),
        136px 0 0 -6px rgba(0, 50, 126, 0.12),
        137px 0 0 -6px rgba(0, 50, 126, 0.12),
        138px 0 0 -6px rgba(0, 50, 126, 0.12),
        139px 0 0 -6px rgba(0, 50, 126, 0.12),
        140px 0 0 -6px rgba(0, 50, 126, 0.12),
        141px 0 0 -6px rgba(0, 50, 126, 0.12),
        142px 0 0 -6px rgba(0, 50, 126, 0.12),
        143px 0 0 -6px rgba(0, 50, 126, 0.12),
        144px 0 0 -6px rgba(0, 50, 126, 0.12),
        145px 0 0 -6px rgba(0, 50, 126, 0.12),
        146px 0 0 -6px rgba(0, 50, 126, 0.12),
        147px 0 0 -6px rgba(0, 50, 126, 0.12),
        148px 0 0 -6px rgba(0, 50, 126, 0.12),
        149px 0 0 -6px rgba(0, 50, 126, 0.12),
        150px 0 0 -6px rgba(0, 50, 126, 0.12),
        151px 0 0 -6px rgba(0, 50, 126, 0.12),
        152px 0 0 -6px rgba(0, 50, 126, 0.12),
        153px 0 0 -6px rgba(0, 50, 126, 0.12),
        154px 0 0 -6px rgba(0, 50, 126, 0.12),
        155px 0 0 -6px rgba(0, 50, 126, 0.12),
        156px 0 0 -6px rgba(0, 50, 126, 0.12),
        157px 0 0 -6px rgba(0, 50, 126, 0.12),
        158px 0 0 -6px rgba(0, 50, 126, 0.12),
        159px 0 0 -6px rgba(0, 50, 126, 0.12),
        160px 0 0 -6px rgba(0, 50, 126, 0.12),
        161px 0 0 -6px rgba(0, 50, 126, 0.12),
        162px 0 0 -6px rgba(0, 50, 126, 0.12),
        163px 0 0 -6px rgba(0, 50, 126, 0.12),
        164px 0 0 -6px rgba(0, 50, 126, 0.12),
        165px 0 0 -6px rgba(0, 50, 126, 0.12),
        166px 0 0 -6px rgba(0, 50, 126, 0.12),
        167px 0 0 -6px rgba(0, 50, 126, 0.12),
        168px 0 0 -6px rgba(0, 50, 126, 0.12),
        169px 0 0 -6px rgba(0, 50, 126, 0.12),
        170px 0 0 -6px rgba(0, 50, 126, 0.12),
        171px 0 0 -6px rgba(0, 50, 126, 0.12),
        172px 0 0 -6px rgba(0, 50, 126, 0.12),
        173px 0 0 -6px rgba(0, 50, 126, 0.12),
        174px 0 0 -6px rgba(0, 50, 126, 0.12),
        175px 0 0 -6px rgba(0, 50, 126, 0.12),
        176px 0 0 -6px rgba(0, 50, 126, 0.12),
        177px 0 0 -6px rgba(0, 50, 126, 0.12),
        178px 0 0 -6px rgba(0, 50, 126, 0.12),
        179px 0 0 -6px rgba(0, 50, 126, 0.12),
        180px 0 0 -6px rgba(0, 50, 126, 0.12),
        181px 0 0 -6px rgba(0, 50, 126, 0.12),
        182px 0 0 -6px rgba(0, 50, 126, 0.12),
        183px 0 0 -6px rgba(0, 50, 126, 0.12),
        184px 0 0 -6px rgba(0, 50, 126, 0.12),
        185px 0 0 -6px rgba(0, 50, 126, 0.12),
        186px 0 0 -6px rgba(0, 50, 126, 0.12),
        187px 0 0 -6px rgba(0, 50, 126, 0.12),
        188px 0 0 -6px rgba(0, 50, 126, 0.12),
        189px 0 0 -6px rgba(0, 50, 126, 0.12),
        190px 0 0 -6px rgba(0, 50, 126, 0.12),
        191px 0 0 -6px rgba(0, 50, 126, 0.12),
        192px 0 0 -6px rgba(0, 50, 126, 0.12),
        193px 0 0 -6px rgba(0, 50, 126, 0.12),
        194px 0 0 -6px rgba(0, 50, 126, 0.12),
        195px 0 0 -6px rgba(0, 50, 126, 0.12),
        196px 0 0 -6px rgba(0, 50, 126, 0.12),
        197px 0 0 -6px rgba(0, 50, 126, 0.12),
        198px 0 0 -6px rgba(0, 50, 126, 0.12),
        199px 0 0 -6px rgba(0, 50, 126, 0.12),
        200px 0 0 -6px rgba(0, 50, 126, 0.12),
        201px 0 0 -6px rgba(0, 50, 126, 0.12),
        202px 0 0 -6px rgba(0, 50, 126, 0.12),
        203px 0 0 -6px rgba(0, 50, 126, 0.12),
        204px 0 0 -6px rgba(0, 50, 126, 0.12),
        205px 0 0 -6px rgba(0, 50, 126, 0.12),
        206px 0 0 -6px rgba(0, 50, 126, 0.12),
        207px 0 0 -6px rgba(0, 50, 126, 0.12),
        208px 0 0 -6px rgba(0, 50, 126, 0.12),
        209px 0 0 -6px rgba(0, 50, 126, 0.12),
        210px 0 0 -6px rgba(0, 50, 126, 0.12),
        211px 0 0 -6px rgba(0, 50, 126, 0.12),
        212px 0 0 -6px rgba(0, 50, 126, 0.12),
        213px 0 0 -6px rgba(0, 50, 126, 0.12),
        214px 0 0 -6px rgba(0, 50, 126, 0.12),
        215px 0 0 -6px rgba(0, 50, 126, 0.12),
        216px 0 0 -6px rgba(0, 50, 126, 0.12),
        217px 0 0 -6px rgba(0, 50, 126, 0.12),
        218px 0 0 -6px rgba(0, 50, 126, 0.12),
        219px 0 0 -6px rgba(0, 50, 126, 0.12),
        220px 0 0 -6px rgba(0, 50, 126, 0.12),
        221px 0 0 -6px rgba(0, 50, 126, 0.12),
        222px 0 0 -6px rgba(0, 50, 126, 0.12),
        223px 0 0 -6px rgba(0, 50, 126, 0.12),
        224px 0 0 -6px rgba(0, 50, 126, 0.12),
        225px 0 0 -6px rgba(0, 50, 126, 0.12),
        226px 0 0 -6px rgba(0, 50, 126, 0.12),
        227px 0 0 -6px rgba(0, 50, 126, 0.12),
        228px 0 0 -6px rgba(0, 50, 126, 0.12),
        229px 0 0 -6px rgba(0, 50, 126, 0.12),
        230px 0 0 -6px rgba(0, 50, 126, 0.12),
        231px 0 0 -6px rgba(0, 50, 126, 0.12),
        232px 0 0 -6px rgba(0, 50, 126, 0.12),
        233px 0 0 -6px rgba(0, 50, 126, 0.12),
        234px 0 0 -6px rgba(0, 50, 126, 0.12),
        235px 0 0 -6px rgba(0, 50, 126, 0.12),
        236px 0 0 -6px rgba(0, 50, 126, 0.12),
        237px 0 0 -6px rgba(0, 50, 126, 0.12),
        238px 0 0 -6px rgba(0, 50, 126, 0.12),
        239px 0 0 -6px rgba(0, 50, 126, 0.12),
        240px 0 0 -6px rgba(0, 50, 126, 0.12);
    border: 1px solid rgba(0, 30, 75, 0.12);
}
.custom-range::-moz-range-track {
    background: rgba(0, 50, 126, 0.12);
}
.custom-range::-moz-range-thumb {
    background: #fff;
    border: 1px solid rgba(0, 30, 75, 0.12);
}
.custom-range::-moz-range-progress {
    background: #467fcf;
}
.custom-range::-ms-thumb {
    background: #fff;
    border: 1px solid rgba(0, 30, 75, 0.12);
}
.custom-range::-ms-fill-lower {
    background: #467fcf;
}
.custom-range::-ms-fill-upper {
    background: rgba(0, 50, 126, 0.12);
}
.selectgroup-button {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #77778e;
}
.selectgroup-input:checked + .selectgroup-button {
    background: #2e2e4a;
}
.selectgroup-input:focus + .selectgroup-button {
    box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}
.custom-switch-indicator {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.custom-switch-indicator:before {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}
.custom-switch-input:focus ~ .custom-switch-indicator {
    box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
    border-color: #7c46a8;
}
.custom-switch-description,
.custom-switch-input:checked ~ .custom-switch-description {
    color: #77778e;
}
.imagecheck-figure {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.imagecheck-input:focus ~ .imagecheck-figure {
    border-color: #467fcf;
    box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}
.imagecheck-input:checked ~ .imagecheck-figure {
    border-color: rgba(0, 40, 100, 0.24);
}
.imagecheck-figure:before {
    background: #467fcf
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
        no-repeat center center / 50% 50%;
    color: #fff;
}
.imagecheck-caption,
.imagecheck:hover .imagecheck-caption {
    color: #77778e;
}
.imagecheck-input:focus ~ .imagecheck-figure .imagecheck-caption,
.imagecheck-input:checked ~ .imagecheck-figure .imagecheck-caption {
    color: #77778e;
}
.colorinput-color {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.colorinput-input:focus ~ .colorinput-color {
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}
.ui-datepicker {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.ui-datepicker .ui-datepicker-header {
    color: #dedefd;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
    text-indent: -99999px;
    color: #6c757d;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before {
    color: #343a40;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
    color: #343a40;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
    color: #77778e;
}
.ui-datepicker .ui-datepicker-calendar th {
    color: #77778e;
}
.ui-datepicker .ui-datepicker-calendar td {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
}
.ui-datepicker .ui-datepicker-calendar td span {
    background-color: #2e2e4a;
    color: #77778e;
}
.ui-datepicker .ui-datepicker-calendar td a {
    background-color: #2e2e4a;
    color: #77778e;
}
.ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: #2e2e4a;
    color: #dedefd;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: #2e2e4a;
    color: #dedefd;
}
.model-wrapper-demo {
    background: #2e2e4a;
}
.timeline__item:after {
    background: #fff !important;
}
.timeline__content {
    background-color: #fff;
}
#back-to-top {
    color: #fff;
}
#back-to-top:hover {
    background: #22223d !important;
}
.timeline:before {
    background-color: rgba(255, 255, 255, 0.1);
}
.timeline-item:first-child:before,
.timeline-item:last-child:before {
    background: #fff;
}
.timeline-time {
    color: #77778e;
}
.jvectormap-tip {
    background: #83839e;
    color: white;
}
.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
    background: #22223d;
}
.selectize-control.plugin-drag_drop.multi
    > .selectize-input
    > div.ui-sortable-placeholder {
    background: #f2f2f2 !important;
    background: rgba(0, 0, 0, 0.06) !important;
    box-shadow: inset 0 0 12px 4px #fff;
}
.selectize-control.plugin-drag_drop .ui-sortable-helper {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.selectize-dropdown-header {
    border-bottom: 1px solid #d0d0d0;
    background: #f8f8f8;
}
.selectize-dropdown-header-close {
    color: #77778e;
}
.selectize-dropdown-header-close:hover {
    color: #000;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup {
    border-right: 1px solid #f2f2f2;
}
.selectize-control.plugin-remove_button [data-value] .remove {
    border-left: 1px solid #d0d0d0;
}
.selectize-control.plugin-remove_button [data-value].active .remove {
    border-left-color: #cacaca;
}
.selectize-control.plugin-remove_button .disabled [data-value] .remove {
    border-left-color: #fff;
}
.selectize-dropdown,
.selectize-input {
    color: #77778e;
}
@media (max-width: 992px) {
    .about-con {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
}
@media (max-width: 480px) {
    .tabs-menu ul li a,
    .tabs-menu1 ul li {
        border: 1px solid rgba(255, 255, 255, 0.1);
    }
}
@media (max-width: 320px) {
    .construction .btn.btn-icon {
        color: #fff;
    }
}
@media (max-width: 360px) {
    .breadcrumb {
        color: #fff;
    }
}
@media (max-width: 992px) {
    .header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
}
@media (max-width: 768px) {
    .richText .richText-toolbar ul li a {
        border: rgba(0, 40, 100, 0.12) solid 1px;
    }
    .header-1 .navsearch i {
        color: #000 !important;
    }
}
@media (max-width: 767px) {
    .mobile-header .nav-link.icon i {
        color: #fff !important;
    }
}
@media (min-width: 768px) {
    .dark-mode.sidebar-mini.sidenav-toggled .app-sidebar__toggle.close-toggle {
        color: #fff;
    }
}
@media (max-width: 992px) and (min-width: 768px) {
    .hor-header .nav-link.icon i {
        color: #fff !important;
    }
}
@media (max-width: 768px) {
    .responsive-navbar.navbar .navbar-collapse .icon.navsearch {
        border: 1px solid #e4e6f9;
    }
}
.selectize-input input {
    color: #77778e;
}
.selectize-input,
.selectize-control.single .selectize-input.input-active {
    background: #fff;
}
.selectize-input {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.selectize-input.full {
    background-color: #fff;
}
.selectize-input.focus {
    border-color: #467fcf;
    box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}
.selectize-control.multi .selectize-input > div {
    background: rgba(255, 255, 255, 0.1);
    color: #77778e;
    border: 0 solid rgba(0, 40, 100, 0.12);
}
.selectize-control.multi .selectize-input > div.active {
    background: #e8e8e8;
    color: #303030;
    border: 0 solid #cacaca;
}
.selectize-control.multi .selectize-input.disabled > div {
    color: #7d7d7d;
    background: #fff;
    border: 0 solid #fff;
}
.selectize-control.multi .selectize-input.disabled > div.active {
    color: #7d7d7d;
    background: #fff;
    border: 0 solid #fff;
}
.selectize-input.dropdown-active::before {
    background: #f0f0f0;
}
.selectize-dropdown {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #fff;
}
.selectize-dropdown [data-selectable] .highlight {
    background: rgba(125, 168, 208, 0.2);
}
.selectize-dropdown .optgroup-header {
    color: #77778e;
    background: #fff;
}
.selectize-dropdown .active {
    background-color: #f1f4f8;
    color: #467fcf;
}
.selectize-dropdown .active.create {
    color: #77778e;
}
.selectize-dropdown .create {
    color: rgba(48, 48, 48, 0.5);
}
.selectize-control.single .selectize-input:after {
    background: #fff
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E")
        no-repeat center;
}
.selectize-control .selectize-input.disabled {
    background-color: #fafafa;
}
.selectize-dropdown .image img,
.selectize-input .image img {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}
.demo-gallery > ul > li a {
    border: 3px solid #fff;
}
.demo-gallery > ul > li a .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
}
.demo-gallery .justified-gallery > a .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
}
.demo-gallery .justified-gallery > a:hover .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.5);
}
.demo-gallery.dark > ul > li a {
    border: 3px solid #04070a;
}
.col-sm-3 a:hover {
    border: 1px solid #ff4647;
}
.tabs-menu ul li .active {
    color: #2a2635;
}
.tabs-menu-body,
.tab-menu-heading {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.tabs-menu2 ul li a {
    color: #636262;
}
.tabs-menu2 ul li .fade {
    color: #eeee;
}
.item-card .cardtitle a,
.item-card .cardprice span {
    color: #7a7a92;
}
.features span {
    color: #43414e;
}
.feature .border {
    color: #fff;
}
.rating-stars .rating-stars-container .rating-star {
    color: #282840;
}
.rating-stars .rating-stars-container .rating-star.sm {
    color: rgba(255, 255, 255, 0.1);
}
.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
    color: #f1c40f;
}
.rating-stars .rating-stars-container .rating-star.is--no-hover,
.rating-stars .rating-stars-container .rating-star .fa-heart .is--no-hover {
    color: #2e2e4a;
}
.rating-stars input {
    color: #77778e;
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
#messages-main .ms-menu {
    border-right: 1px solid #eee;
    background: #fff;
}
#messages-main .ms-user {
    background: #f8f8f8;
}
#messages-main #ms-compose {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}
.message-feed:not(.right) .mf-content {
    color: #fff;
}
.message-feed.right .mf-content {
    background: #2e2e4a;
}
.message-feed.right .mf-content:before {
    border-left-color: #2e2e4a;
}
.msb-reply {
    background: #2e2e4a;
}
.four-zero,
.lc-block {
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
}
.msb-reply button {
    color: #fff;
}
.action-header {
    background: #f8f8f8;
}
.actions:not(.a-alt) > li > a > i {
    color: #939393;
}
.ms-body {
    background: #fff;
}
.richText {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #2e2e4a;
}
.richText .richText-toolbar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.richText .richText-toolbar ul li a {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.cal1 .clndr .clndr-table .header-days .header-day {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.cal1 .clndr .clndr-table tr .empty,
.cal1 .clndr .clndr-table tr .adjacent-month,
.cal1 .clndr .clndr-table tr .my-empty,
.cal1 .clndr .clndr-table tr .my-adjacent-month {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: #2e2e4a;
}
.cal1 .clndr .clndr-table tr .day {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.cal1 .clndr .clndr-table tr .day.event,
.cal1 .clndr .clndr-table tr .day.my-event {
    background: #2e2e4a;
}
.cal1 .clndr .clndr-table tr .day:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.cal1 .clndr .clndr-table tr .day:hover {
    background: #2e2e4a;
}
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button,
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button {
    color: #fff;
}
.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
    border-color: rgba(255, 255, 255, 0.1);
}
.fc-event,
.fc-event-dot {
    color: #fff !important;
}
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
    background: #2e2e4a;
}
.fc-unthemed .fc-list-item:hover td {
    background-color: #2e2e4a;
}
.conv-form-wrapper div#messages div.message.to {
    background: #2e2e4a;
}
#sidebar li a.active {
    background: #fff;
}
#sidebar li a.active a[data-toggle='collapse'] {
    background: #fff;
}
.job-box-filter {
    border-bottom: 1px solid #e4e4e4;
}
.job-box-filter select.input-sm,
.job-box-filter label input.form-control {
    border: 1px solid rgba(119, 119, 119, 0.3);
}
.job-box-filter a.filtsec {
    background: #edf0f3;
    color: #81a0b1;
    border: 1px solid #e2e8ef;
}
.job-box-filter a.filtsec.active {
    color: #ffffff;
    background: #16262c;
    border-color: #16262c;
}
.job-box-filter a.filtsec i {
    color: #03a9f4;
}
.job-box-filter a.filtsec:hover,
.job-box-filter a.filtsec:focus {
    color: #ffffff;
    background: #07b107;
    border-color: #07b107;
}
.job-box-filter a.filtsec:hover i,
.job-box-filter a.filtsec:focus i {
    color: #ffffff;
}
.inbox-message ul li {
    border-bottom: 1px solid #e4e4e4;
}
.inbox-message ul li:hover,
.inbox-message ul li:focus {
    background: #eeeeee;
}
.inbox-message .message-body {
    color: #999999;
}
.message-body-heading h5 {
    color: #999999;
}
.message-body h5 span {
    color: #fff;
}
.message-body h5 span.unread {
    background: #07b107;
}
.message-body h5 span.important {
    background: #dd2027;
}
.message-body h5 span.pending {
    background: #11a3dd;
}
div.conv-form-wrapper div.options div.option.selected,
form.convFormDynamic button.submit:hover {
    color: #fff;
}
.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value {
    background: rgba(111, 63, 115, 0.9) !important;
}
.addui-slider
    .addui-slider-track
    .addui-slider-handle.addui-slider-handle-active {
    background: rgba(111, 63, 115, 0.5);
    box-shadow: 0 0 5px rgba(111, 63, 115, 0.5);
}
.accordionjs .acc_section.acc_active > .acc_head {
    color: #fff !important;
}
.timeline__item--right .timeline__content:before {
    border-right: 12px solid rgba(238, 232, 239, 0.9);
}
.cal1 .clndr .clndr-table tr .empty:hover,
.cal1 .clndr .clndr-table tr .adjacent-month:hover,
.cal1 .clndr .clndr-table tr .my-empty:hover,
.cal1 .clndr .clndr-table tr .my-adjacent-month:hover {
    background: #2a2635;
}
.chart-dropshadow {
    -webkit-filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
}
.chart-dropshadow2 {
    -webkit-filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.2));
}
.line-divide {
    border: 1px solid rgba(218, 216, 219, 0.2);
}
.activity {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
}
.activity .img-activity {
    -webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
    box-shadow: 0 0 0 0.5px #f8f9fa;
}
.bg-square {
    color: #fff;
    background: #77778e;
}
ul.timeline:before {
    background: #d4d9df;
}
ul.timeline > li:before {
    border: 1px solid #6c6c6f;
}
.weather-card {
    background: #fff;
}
.weather-card .top .wrapper .mynav .lnr,
.weather-card .top .wrapper .heading,
.weather-card .top .wrapper .location {
    color: #fff;
}
.weather-card .top .wrapper .temp a,
.weather-card .top .wrapper .temp .temp-value,
.weather-card .top .wrapper .temp .deg {
    color: #fff;
}
.weather-card .bottom {
    background: #fff;
}
.weather-card .bottom .wrapper .forecast a {
    color: #000;
}
.weather-card .bottom .wrapper .forecast li {
    color: rgba(0, 0, 0, 0.25);
}
.weather-card .bottom .wrapper .forecast li.active {
    color: rgba(0, 0, 0, 0.8);
}
.activity .img-activity {
    -webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
    box-shadow: 0 0 0 0.5px #f8f9fa;
}
.card .box h2 {
    color: #262626;
}
.card .box h2 span {
    color: #fff;    
}
.card .box p {
    color: #262626;
}
.userprofile .userpic {
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    position: relative;
}
.userlist a:hover {
    color: #fff;
}
.username {
    color: #fff;
}
.username + p {
    color: #f2f2f2;
}
.settingbtn {
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
}
.userprofile.small .userpic {
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.userprofile.small .username {
    color: #000000;
}
.userprofile.small .username + p {
    color: #607d8b;
}
.text-transparent {
    color: #fff;
}
.bg-primary-light {
    background: #fff;
}
.product-grid6 .price span {
    color: #77778e;
}
.product-grid6 .icons li a:hover,
.product-grid6 .icons li a:after,
.product-grid6 .icons li a:before {
    color: #fff;
}
.card-counter {
    background-color: #fff;
}
.card-counter.primary,
.card-counter.secondary,
.card-counter.success,
.card-counter.info {
    color: #fff;
}
.gallery-wrap .img-small-wrap .item-gallery {
    border: 1px solid #ddd;
}
.input-group-addon {
    color: #555;
}
.ribbon1 {
    color: #fff;
}
.ribbon1:after {
    border-top: 13px solid #f8463f;
}
.ribbon1 span {
    background: #f8463f;
}
.ribbon1 span:before {
    background: #f8463f;
}
.ribbon1 span:after {
    background: #c02031;
}
.ribbon span {
    color: #fff;
    background: #79a70a;
    background: linear-gradient(#f8463f 0%, #f8463f 100%);
}
.ribbon span::before {
    border-left: 3px solid #f8463f;
    border-top: 3px solid #f8463f;
}
.ribbon span::after {
    border-right: 3px solid #f8463f;
    border-top: 3px solid #f8463f;
}
.social-card-header i {
    color: #fff;
}
#user-profile .profile-status {
    color: #7e47a9;
}
#user-profile .profile-status.offline {
    color: #fe635f;
}
#user-profile .profile-stars {
    color: #faba03;
}
#user-profile .profile-details ul > li > span {
    color: #643ba2;
}
#user-profile .profile-image {
    border: 1px solid rgba(167, 180, 201, 0.2);
    background: rgba(240, 243, 249, 0.6);
}
#user-profile .profile-image:hover {
    background: rgba(164, 166, 169, 0.9);
}
.main-box {
    background: #ffffff;
    -webkit-box-shadow: 1px 1px 2px 0 #cccccc;
    -moz-box-shadow: 1px 1px 2px 0 #cccccc;
    -o-box-shadow: 1px 1px 2px 0 #cccccc;
    -ms-box-shadow: 1px 1px 2px 0 #cccccc;
    box-shadow: 1px 1px 2px 0 #cccccc;
}
.main-box h2 {
    border-left: 10px solid #c2c2c2;
    /*7e8c8d*/
}
.conversation-body {
    background: #f5f5f5;
}
.conversation-body:before {
    border-color: transparent #f5f5f5 transparent transparent;
}
.conversation-item.item-right .conversation-body {
    background: #efeef1;
}
.conversation-item.item-right .conversation-body:before {
    border-color: transparent transparent transparent #efeef1;
}
.conversation-body > .time {
    color: #605f5f;
}
.bg-light-gray {
    background-color: #2e2e4a;
}
.header .form-inline .form-control {
    border-color: rgba(225, 225, 225, 0.1);
    background: rgba(225, 225, 225, 0.3);
    color: #dedef1 !important;
}
.header .form-inline .btn {
    color: #fff;
}
.bg-progress-white {
    background-color: #eaeceb;
}
.widget-card-1 i {
    color: #fff;
}
.widget-card-1 i:hover {
    color: #fff;
}
.socailicons i {
    color: #edf5f1;
}
.settings {
    color: #fff;
}
.setting-1 {
    color: #04a9f5;
}
.wideget-user .wideget-user-icons a:hover {
    color: #fff;
}
.wideget-user-tab .tab-menu-heading {
    border: 0;
}
.wideget-user-tab .tab-menu-heading .nav li a {
    color: #dedefd;
}
#profile-log-switch .table td,
#profile-log-switch .table th,
#profile-log-switch .table tbody + tbody {
    border-top: 0;
}
/* line 77, C:/wamp/www/github/addSlider/src/partials/_addSlider.scss */
/*----chart-drop-shadow----*/
@media (max-width: 767.98px) and (min-width: 576px) {
    .header .navsearch i {
        font-size: 20px;
        color: #fff;
    }
    .search-element .form-control {
        background: #fff !important;
        color: #000;
    }
    .header .form-inline .form-control::-webkit-input-placeholder {
        color: #77778e;
    }
    .header .navsearch i {
        color: #fff;
    }
    .header .form-inline .btn {
        color: #46494a !important;
    }
}
@media (max-width: 1024px) {
    body.dark-mode.search-show:before,
    body.dark-mode.sidebar-show:before {
        background-color: rgba(0, 0, 0, 0.4);
    }
}
@media (max-width: 575.98px) {
    .header .form-inline .search-element .form-control {
        background: #fff;
    }
    .form-control.header-search {
        color: #000;
    }
    .header .form-inline .btn {
        color: #46494a !important;
    }
    .header .form-inline .form-control::-webkit-input-placeholder {
        color: #77778e !important;
    }
    .header .navsearch i {
        color: #fff;
    }
}
#profile-log-switch .text-wrap table tbody + tbody {
    border-top: 0;
}
.card-aside-img.wrap-border img {
    border: 1px solid rgba(167, 180, 201, 0.3);
}
.card-blogs1 .card {
    border-bottom: 1px solid rgba(167, 180, 201, 0.3);
}
.info-box-icon {
    background: rgba(0, 0, 0, 0.2);
}
.info-box {
    background: #fff;
    box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}
.member {
    background: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.memmbername {
    color: #ffffff;
}
.header .bg-image a:hover {
    background: transparent !important;
}
.construction .btn.btn-icon,
.item-card2-icons a {
    color: #fff;
}
.item-list .list-group-item {
    border-bottom: 1px solid rgba(167, 180, 201, 0.3);
}
.item-list .list-group-item i {
    color: #fff !important;
}
.item-user .item-user-icons a:hover {
    color: #fff;
}
.border-danger {
    border: 1px solid #ff416c;
}
.productdec {
    background: #f2faff;
}
.cart .input-group-prepend > .btn,
.input-group-append > .btn,
.input-group-btn > .btn {
    border-color: #e2a788;
}
.input-indec .input-group-btn > .btn {
    border-color: rgba(255, 255, 255, 0.1);
}
.gallery a img {
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.hover15 .card::before {
    background: rgba(255, 255, 255, 0.2);
}
.contacts_body .contacts li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.contacts_body .active {
    background-color: #2e2e4a;
}
.chatbox .user_img {
    border: 1.5px solid #2e2e4a;
}
.chatbox .user_img_msg {
    border: 1.5px solid #f5f6fa;
}
.chat abbr.timestamp {
    background: #2e2e4a;
}
.chatbox .user_info span {
    color: #77778e;
}
.chat .msg_cotainer {
    background-color: #2e2e4a;
}
.chat .msg_cotainer_send {
    background-color: #2b2b44;
}
.chat .msg_time,
.chat .msg_time_send {
    color: #77778e;
}
.chat .msg_head {
    box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.4);
}
.action_menu {
    background-color: #fff;
    color: #646367;
}
.chat .action-header {
    background: #2a2635;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.chat .msb-reply textarea {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #2e2e4a;
    color: #dedefd;
}
.msb-reply button {
    color: #fff;
}
.chat .dropdown-menu li {
    color: #77778e;
}
.chat .dropdown-menu li:hover {
    background: #2e2e4a;
}
.action_menu ul li:hover {
    background-color: rgba(0, 0, 0, 0.2);
}
.top-footer ul li {
    border-bottom: 0;
}
.chat .actions:not(.a-alt) > li > a > i {
    color: #77778e;
}
.top-footer p {
    color: #77778e;
}
.top-footer a {
    color: #77778e;
    color: #77778e;
}
.top-footer a address {
    color: #77778e;
}
.top-footer img {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.top-footer img:hover {
    color: #8e9090;
}
.footer-payments a {
    color: #a7a8c7;
}
.main-footer {
    background-color: #2a2635;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.cbp_tmtimeline:before {
    background: #2a2635;
}
.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
    background: #2a2635;
}
.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: #2a2635;
}
.cbp_tmtimeline > li .cbp_tmlabel {
    background: #2a2635;
    box-shadow: 0 0 2rem 0 rgba(42, 38, 53, 0.15) !important;
}
.cbp_tmtimeline > li .cbp_tmlabel:after {
    border-right-color: #2a2635;
}
.cbp_tmtimeline > li .cbp_tmlabel .map-checkin {
    border: 5px solid rgba(235, 235, 235, 0.2);
    -moz-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
    background: #3d3780 !important;
}
.cbp_tmtimeline > li .cbp_tmicon {
    color: #fff;
    background: #46a4da;
}
.header-1 .navbar .nav-link.nav-link-lg i,
.header-1 .form-inline .btn,
.header-1 .form-control.header-search {
    color: #dedef1 !important;
}
.header-1 .form-inline .form-control::-webkit-input-placeholder {
    color: #77778e;
}
.header-1 .nav-link.icon i {
    color: #77778e !important;
}
.header-1 .dropdown-menu {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
}
.header-2 .dropdown-menu {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
}
.header-2 .nav-link i {
    color: #77778e;
}
.header-2 .form-inline .btn {
    color: #fff;
}
.header-2 .form-inline .form-control::-webkit-input-placeholder {
    color: #77778e;
}
.header-2 .form-control.header-search {
    background: rgba(225, 225, 225, 0.4);
    border: 1px solid rgba(225, 225, 225, 0.1);
    color: #fff;
}
.horizontal-main2 .horizontalMenu > .horizontalMenu-list > li > a.active {
    color: #d43f8d;
}
.horizontal-main2.horizontal-main {
    background: transparent;
}
.header-3 .nav-link i {
    color: #25252a;
}
.header-3 .form-inline .btn {
    color: #25252a;
}
.header-3 .form-inline .form-control::-webkit-input-placeholder {
    color: #77778e;
}
.header-3 .form-control.header-search {
    background: rgba(225, 225, 225, 0.4);
    border: 1px solid rgba(225, 225, 225, 0.1);
    color: #fff;
}
.horizontal-main3.horizontal-main {
    background: rgba(52, 1, 108, 0.8);
}
.default-header .form-inline .form-control,
.header2 .form-inline .form-control,
.header3 .form-inline .form-control {
    background: #2e2e4a !important;
}
.border-right-1 {
    border-right: 1px solid #d5dce3;
}
.custom-switch-indicator {
    background: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.custom-switch-indicator:before {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}
.custom-switch-input:focus ~ .custom-switch-indicator {
    border-color: #60529f;
}
.custom-switch-description,
.custom-switch-input:checked ~ .custom-switch-description {
    color: #77778e;
}
.material-switch > label::before {
    background: #77778e;
    box-shadow: inset 0px 0px 10px rgba(42, 38, 53, 0.5);
}
.material-switch > label::after {
    background: #3d3d5a;
    box-shadow: 0px 0px 5px rgba(42, 38, 53, 0.9);
}
.header-right-icons .nav-link.icon:hover {
    background: rgba(0, 0, 0, 0.2);
}
.header .form-control {
    border: 1px solid rgba(255, 255, 255, 0.05);
}
.box-white-shadow {
    box-shadow: 0 5px 10px rgba(255, 255, 255, 0.3) !important;
}
.chart-dropshadow {
    -webkit-filter: drop-shadow(-6px 5px 4px #2a2635);
    filter: drop-shadow(-6px 5px 4px #2a2635);
}
.chart-dropshadow2 {
    -webkit-filter: drop-shadow(-6px 5px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(-6px 5px 4px rgba(0, 0, 0, 0.2));
}
.BarChartShadow {
    -webkit-filter: drop-shadow(-4px 9px 4px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(-6px 9px 4px rgba(0, 0, 0, 0.3));
}
.donutShadow {
    -webkit-filter: drop-shadow(-5px 4px 6px #2a2635);
    filter: drop-shadow(-1px 0px 2px #2a2635);
}
.demo_changer .bg_dark {
    background: #fff;
}
.demo_changer i {
    color: #000;
}
.hor-header .header-brand-img.desktop-logo {
    display: none;
}
.hor-header .header-brand-img.light-logo {
    display: block;
}
.table {
    color: #dedefd;
}
.text-dark {
    color: #dedefd !important;
}
.bg-white.work-progress {
    background: #2a2635 !important;
}
.table-hover tbody tr:hover {
    color: #dedefd;
}
@media screen and (max-width: 47.2em) {
    .cbp_tmtimeline > li .cbp_tmlabel:after,
    .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
        border-bottom-color: #fff;
    }
}
@media only screen and (max-width: 991px) {
    .dark-mode.active .animated-arrow span {
        background: transparent;
    }
    .header-1 .animated-arrow span {
        background: #fff;
    }
    .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
        border-top: 0 !important;
    }
    .animated-arrow span:before,
    .animated-arrow span:after {
        background: #fff;
    }
    .header-1 .animated-arrow span {
        background: #fff;
    }
    .animated-arrow span:before,
    .animated-arrow span:after {
        background: #fff;
    }
    .header-1 .navbar .nav-link.nav-link-lg i {
        color: #25252a;
    }
    .header-1 .dropdown-menu {
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
    }
}
@media only screen and (max-width: 991px) {
    .header-1 .animated-arrow span {
        background: #25252a;
        color: #25252a;
    }
    .header-1 .animated-arrow span:before,
    .header-1 .animated-arrow span:after {
        background: #25252a;
        color: #25252a;
    }
}
@media only screen and (max-width: 1279px) and (min-width: 1025px) {
    .horizontal-main2 .horizontalMenu > .horizontalMenu-list > li > a {
        color: #fcfeff;
    }
}
@media only screen and (max-width: 991px) {
    .header-2 .animated-arrow span {
        background: #25252a;
        color: #25252a;
    }
    .header-2 .animated-arrow span:before,
    .header-2 .animated-arrow span:after {
        background: #25252a;
        color: #25252a;
    }
}
@media only screen and (max-width: 1279px) and (min-width: 1025px) {
    .horizontal-main3 .horizontalMenu > .horizontalMenu-list > li > a {
        color: #fcfeff;
    }
}
@media only screen and (max-width: 991px) {
    .header-3 .animated-arrow span {
        background: #25252a;
        color: #25252a;
    }
    .header-3 .animated-arrow span:before,
    .header-3 .animated-arrow span:after {
        background: #25252a;
        color: #25252a;
    }
}
@media screen and (max-width: 998px) and (min-width: 768px) {
    .note-popover .popover-content,
    .card-header.note-toolbar {
        background: #2e2e4a;
    }
}
.btn-secondary {
    color: #fff !important;
}
#line-chart text {
    fill: #77778e;
}
#line-chart path {
    border: rgba(119, 119, 142, 0.2);
}
.cal1 .clndr .clndr-controls {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #2a2635;
    border-bottom: 0;
}
.cal1 .clndr .clndr-table tr:last-child .day,
.cal1 .clndr .clndr-table tr:last-child .my-day {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.fc-today-button,
.fc-button-group {
    box-shadow: 0 5px 10px #2a2635;
}
.fc-bgevent-skeleton .fc-bgevent {
    background-color: #1e1e31 !important;
}
.form-control.bg-white {
    background-color: #2e2e4a !important;
}
.table td,
.table th {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.sweet-alert {
    background-color: #2a2635;
}
.sweet-alert h2 {
    color: #dedefd;
}
.sweet-alert p {
    color: #77778e;
}
.irs-from,
.irs-to,
.irs-single {
    background: #282840;
}
.irs-from,
.irs-to,
.irs-single {
    color: #dedefd;
}
.irs-min,
.irs-max {
    color: #dedefd;
    background: #282840;
}
.irs-grid-text {
    color: #77778e;
}
.btn-link {
    box-shadow: 0 5px 10px rgba(42, 38, 53, 0.3);
}
.btn-outline-default {
    color: #dedefd;
    border-color: rgba(255, 255, 255, 0.1);
}
.btn-outline-default:hover {
    color: #292828;
}
.table .thead-light th,
.text-wrap table .thead-light th {
    background-color: #2e2e4a;
    border-color: rgba(255, 255, 255, 0.1);
}
.table.table-primary {
    color: #83839e;
}
.table.table-secondary {
    color: #83839e;
}
.table.table-secondary th {
    color: #83839e;
}
.dropify-wrapper {
    color: #77778e;
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.dropify-wrapper .dropify-preview {
    background-color: #2e2e4a;
}
.ms-choice {
    color: #77778e;
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.ms-choice.disabled {
    background-color: #2d2d48;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.ms-drop {
    color: #77778e;
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.ms-drop.bottom {
    box-shadow: 0px 8px 14.72px 1.28px #2a2635;
}
.select2-dropdown {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.select2-container--default .select2-results__option[aria-selected='true'] {
    background-color: #2a2635;
}
.select2-container--default.select2-container--disabled
    .select2-selection--single {
    background-color: #2d2d48;
}
.daterangepicker .calendar-table {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.daterangepicker .calendar td {
    color: #77778e !important;
}
.daterangepicker td.off {
    background-color: #2e2e4a;
}
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
    background-color: #2e2e4a;
}
.daterangepicker td.active {
    background-color: #2e2e4a !important;
    color: #dedefd !important;
}
.daterangepicker td.active:hover {
    background-color: #2e2e4a !important;
    color: #dedefd !important;
}
.daterangepicker td:hover {
    background-color: #2e2e4a !important;
    color: #dedefd !important;
}
.daterangepicker td.in-range {
    background-color: #2e2e4a;
}
.daterangepicker:before {
    border-bottom: 7px solid rgba(255, 255, 255, 0.1);
}
.daterangepicker:after {
    border-bottom: 6px solid #2e2e4a;
}
.ui-datepicker
    .ui-datepicker-calendar
    td.ui-datepicker-other-month
    .ui-state-default {
    color: #77778e;
}
.daterangepicker .input-mini {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
    background-color: #2e2e4a;
}
.richText .richText-editor {
    background-color: #2e2e4a;
    border-left: rgba(255, 255, 255, 0.1) solid 0px;
}
.richText .richText-toolbar ul li a:hover {
    background-color: #2e2e4a;
}
.richText
    .richText-toolbar
    ul
    li
    a
    .richText-dropdown-outer
    .richText-dropdown {
    background-color: #2e2e4a;
    border: rgba(255, 255, 255, 0.1) solid 1px;
}
.richText
    .richText-toolbar
    ul
    li
    a
    .richText-dropdown-outer
    .richText-dropdown
    .richText-dropdown-close {
    background: #2e2e4a;
    color: #77778e;
}
.richText .richText-form input[type='text'],
.richText .richText-form input[type='file'],
.richText .richText-form input[type='number'] {
    border: rgba(255, 255, 255, 0.1) solid 1px;
    background: #1b1b2d;
    color: #dedefd;
}
.richText .richText-form select {
    border: rgba(255, 255, 255, 0.1) solid 1px;
    background: #1b1b2d;
    color: #dedefd;
}
.richText
    .richText-toolbar
    ul
    li
    a
    .richText-dropdown-outer
    ul.richText-dropdown
    li
    a {
    border-bottom: rgba(255, 255, 255, 0.1) solid 1px;
}
.richText
    .richText-toolbar
    ul
    li
    a
    .richText-dropdown-outer
    ul.richText-dropdown
    li
    a:hover {
    background: #1b1b2d;
}
.richText .richText-undo,
.richText .richText-redo {
    border-right: rgba(255, 255, 255, 0.1) solid 1px;
}
.note-editor.note-frame .note-editing-area .note-editable {
    background-color: #2e2e4a;
    color: #dedefd;
}
.note-popover .popover-content,
.card-header.note-toolbar {
    background: #2e2e4a;
}
.note-btn-group .btn {
    color: #dedefd !important;
}
.note-placeholder {
    color: #77778e;
}
.sw-theme-default {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.sw-theme-default .step-content {
    background-color: #2a2635;
}
.sw-theme-default .sw-toolbar {
    background: #2a2635;
}
.sw-theme-default > ul.step-anchor > li > a {
    color: #77778e !important;
}
.sw-theme-default > ul.step-anchor > li > a:hover {
    color: #77778e !important;
}
.sw-theme-default > ul.step-anchor > li.done > a {
    color: #77778e !important;
}
.wrap-login100 {
    background: #2a2635;
    box-shadow: 0 3px 9px 0 rgba(28, 28, 51, 0.15);
}
.login100-form-title {
    color: #dedefd;
}
.input100 {
    color: #dedefd;
    background: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.symbol-input100,
.wrap-input100 input::-webkit-input-placeholder {
    color: #77778e;
}
.social-icons .btn-social {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.btn-social > :first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.txt2,
.txt1 {
    color: #77778e;
}
.text-white {
    color: #dedefd !important;
}
.accordionjs .acc_section {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.accordionjs .acc_section .acc_head {
    background: #2e2e4a;
}
.accordionjs .acc_section.acc_active > .acc_head {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.tab_wrapper .content_wrapper {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.tab_wrapper > ul {
    border-bottom: 0;
}
.tab_wrapper.right_side > ul {
    border-bottom: 0;
}
.tab_wrapper.right_side .content_wrapper {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.tab_wrapper.right_side > ul li {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.tab_wrapper.right_side > ul li.active {
    border-color: rgba(255, 255, 255, 0.1);
}
.tab_wrapper.right_side > ul li:after {
    background: rgba(255, 255, 255, 0.1);
}
.tab_wrapper > ul li {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.tab_wrapper > ul li.active:after {
    background: transparent;
}
.header.header-1,
.header.header-2 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.flot-text {
    color: #77778e !important;
}
tspan {
    fill: #77778e !important;
}
.nvd3 text {
    fill: #77778e;
}
.nvd3 .nv-axis line {
    stroke: rgba(119, 119, 142, 0.2);
}
.nvd3 .nv-discretebar .nv-groups text,
.nvd3 .nv-multibarHorizontal .nv-groups text {
    fill: #dedefd;
}
.countdown li {
    background: #2a2635;
    border: 5px solid rgba(255, 255, 255, 0.1);
    color: #dedefd;
}
.app-sidebar {
    background: #2a2635 !important;
    box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.side-header .header-brand-img.light-logo1 {
    display: none;
}
.side-header .header-brand-img.desktop-logo {
    display: block;
}
.app-sidebar__toggle {
    color: #fff;
    background: #2a2635;
    box-shadow: 0px 2px 3px #1a1a2f;
}
.app-sidebar__toggle:hover {
    color: #fff;
}
.side-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    background: #2a2635;
}
.user-info .text-dark {
    color: #fff !important;
}
.user-info .text-muted {
    color: rgba(255, 255, 255, 0.4) !important;
}
.app-sidebar .sidebar-navs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-navs a {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    color: #fff !important;
}
.side-menu h3 {
    color: rgba(255, 255, 255, 0.3);
}
.slide-menu li.active:before {
    background: rgba(255, 255, 255, 0.1);
}
.slide-menu li.active:after {
    background: rgba(255, 255, 255, 0.5);
}
.slide-menu a.active {
    color: #77778e;
}
.slide-item.active,
.slide-item:hover,
.slide-item:focus {
    color: #2a2635 !important;
}
.side-menu .side-menu__icon,
.side-menu__item,
.slide-item {
    color: #dedefd;
}
.sidebar {
    background-color: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
}
.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    background-color: #2a2635;
    box-shadow: 0 10px 15px rgba(34, 34, 61, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a {
    color: #dedefd;
}
.sub-menu li a:before {
    color: #77778e;
}
.horizontalMenu > .horizontalMenu-list > li:last-child > a {
    border-right: 0px;
}
.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu .sub-menu-sub:after {
    color: #77778e;
}
.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
    background-color: #2a2635;
    box-shadow: 0 10px 15px rgba(34, 34, 61, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a {
    color: #dedefd;
}
.mega-menubg {
    background-color: #2a2635;
    box-shadow: 0 10px 15px rgba(34, 34, 61, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .link-list
    li
    a,
.horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .link-list
    li
    a {
    background: #2a2635;
    color: #dedefd;
}
.mega-menubg li a:before {
    color: #77778e;
}
.btn-light {
    color: #dedefd;
    background-color: #2f2f4b;
    border-color: #39393e;
    box-shadow: 0 5px 10px rgba(48, 48, 77, 0.3);
}
.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover {
    background: none;
}
.hor-topheader .header-search.dropdown-menu {
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.clipboard-icon {
    background: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.2);
}
.badge-default {
    background: #2e2e4a;
    color: #fff;
    box-shadow: 0 5px 10px #22223d;
}
.side-header,
.resp-vtabs ul.resp-tabs-list,
.first-sidemenu {
    background: #2a2635;
}
.resp-vtabs li.active.resp-tab-item {
    background: #2a2635 !important;
    color: #5e2dd8;
}
.second-sidemenu {
    background-color: #2a2635;
}
.resp-vtabs .resp-tabs-list li {
    border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
    border: 1px solid rgba(255, 255, 255, 0.07) !important;
    border-left: 0 !important;
    border-top: 0 !important;
}
.resp-vtabs li:hover {
    background: transparent !important;
}
.side-menu .resp-tab-active .side-menu__icon {
    color: #2a2635 !important;
}
.slide-item {
    border-bottom: 0px;
}
.resp-vtabs li.resp-tab-active {
    background: transparent !important;
}
.settings-icon {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.slide-menu a.active {
    color: #aaaabf;
}
/*-- App-Sidebar --*/
/*-- Sidebar --*/
/*--- Horizontal menu ---*/
@media only screen and (max-width: 991px) {
    .mega-menubg {
        background: #2a2635 !important;
        box-shadow: none;
    }
    .hor-menu
        .horizontalMenu
        > .horizontalMenu-list
        > li
        > .horizontal-megamenu
        .link-list
        li
        a {
        background: #2a2635 !important;
        color: #dedefd;
    }
    .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
        box-shadow: none;
        box-shadow: none;
    }
    .horizontalMenu
        > .horizontalMenu-list
        > li
        > ul.sub-menu
        > li
        > ul.sub-menu {
        box-shadow: none;
        border: 0px;
    }
    .hor-topheader .header-brand1 .header-brand-img.main-logo {
        display: none;
    }
    .dark-mode.light-hor-header
        .hor-topheader
        .header-brand1
        .header-brand-img.light-logo {
        display: block;
    }
    .dark-mode.light-hor-header .hor-topheader .header.hor-header {
        background: #2a2635;
    }
    .animated-arrow span {
        background: #fff;
    }
    dark-mode .animated-arrow span:before,
    dark-mode .animated-arrow span:after {
        background: #fff;
    }
    .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click > i {
        color: rgba(255, 255, 255, 0.2);
    }
    .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu li:hover > a {
        background-color: #2a2635;
        color: #dedefd;
    }
    .horizontalMenu
        > .horizontalMenu-list
        > li
        > ul.sub-menu
        > li
        > ul.sub-menu
        > li
        > a:hover {
        background-color: #2e2e4a;
    }
    .horizontalMenu
        > .horizontalMenu-list
        > li
        > .horizontal-megamenu
        .link-list
        li
        a:hover {
        color: #b394ff !important;
    }
}
@media only screen and (max-width: 991px) {
    .dark-mode.light-hormenu
        .horizontalMenu
        > .horizontalMenu-list
        > li
        > ul.sub-menu
        li:hover
        > a {
        background-color: #f1f4fb !important;
    }
}
/*toggle-menu css */
@media (min-width: 768px) {
    .dark-mode.sidebar-mini.sidenav-toggled .first-sidemenu {
        border-top: 0px !important;
    }
    .dark-mode.sidebar-mini.sidenav-toggled .app-sidebar__user {
        border-bottom: 1px solid rgba(225, 225, 225, 0.1);
    }
}
@media (max-width: 767px) {
    .side-menu {
        border-top: 1px solid #3f3f5a;
    }
    .mobile-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
        box-shadow: 0 8px 24px #3d3d52;
    }
}
.horizontal-main.hor-menu {
    border-bottom: 1px solid #464663 !important;
}
.hor-header .header-right-icons .nav-link.icon {
    background: #2a2635;
    box-shadow: 0px 2px 3px #1a1a2f;
}
.horizontalMenu > .horizontalMenu-list > li > a {
    color: #dedefd;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.horizontal-main.hor-menu {
    background: #2a2635;
}
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active {
    color: #2a2635;
}
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #2a2635;
}
.hor-menu .horizontalMenu > .horizontalMenu-list > li:first-child {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.hor-menu .horizontalMenu > .horizontalMenu-list > li:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.box-shadow-primary {
    box-shadow: 0 5px 10px #42444a;
}
.box-shadow-secondary {
    box-shadow: 0 5px 10px #4e4642;
}
.box-shadow-success {
    box-shadow: 0 5px 10px #45504e;
}
.box-shadow-danger {
    box-shadow: 0 5px 10px #524a4b;
}
.bg-secondary-transparent {
    background-color: #614638;
}
.bg-info-transparent {
    background-color: #365273 !important;
}
.task-list:before,
.activity-blog:before {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
}
h6 {
    color: #dedefd;
}
.h6 {
    color: #dedefd;
}
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active,
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #2a2635;
}
.horizontalMenu html,
.horizontalMenu body,
.horizontalMenu iframe,
.horizontalMenu h1,
.horizontalMenu h2,
.horizontalMenu h3,
.horizontalMenu h4,
.horizontalMenu h5,
.horizontalMenu h6 {
    color: #dedefd !important;
}
.horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu {
    background-color: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a {
    color: #dedefd;
}
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active,
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    background: transparent !important;
}
.dark-mode.color-hormenu
    .hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > a.active,
.dark-mode.color-hormenu
    .hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > a:hover {
    background: #6159bb !important;
}
.side-menu__item:hover {
    background: #2e2e4a;
}
@media (max-width: 767px) {
    .mobile-header .header-brand-img.mobile-light {
        display: none !important;
    }
}
@media (max-width: 767px) {
    .mobile-header .header-brand-img.desktop-logo {
        display: block;
    }
}
@media (max-width: 768px) {
    .mobile-header .navresponsive-toggler span {
        background: #2a2635;
        box-shadow: 0px 2px 3px #1a1a2f;
    }
}
@media (max-width: 768px) {
    .sidebar-mini.navresponsive-toggler span {
        background: #2a2635;
        box-shadow: 0px 2px 3px #1a1a2f;
    }
}
@media only screen and (max-width: 991px) {
    .horizontalMenu > .horizontalMenu-list > li > a {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
}
.side-menu__item:focus {
    background: #2e2e4a;
}
.app-header .header-right-icons .nav-link.icon {
    background: #2a2635;
    box-shadow: 0px 2px 3px #1a1a2f;
}
.jvectormap-zoomin {
    color: #e5e9f2;
}
.jvectormap-zoomout,
.jvectormap-goback {
    color: #e5e9f2;
}
.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
    color: #e5e9f2 !important;
}
.dark-mode.sidebar-mini .app-header .light-logo1 {
    display: none !important;
}
.nav.panel-tabs a.active,
.nav.panel-tabs a:hover {
    background-color: #22223d;
}
.cal1 .clndr .clndr-table tr .empty,
.cal1 .clndr .clndr-table tr .adjacent-month,
.cal1 .clndr .clndr-table tr .my-empty {
    background: #21213b !important;
}
.dark-mode.cal1 .clndr .clndr-table tr .my-adjacent-month,
.cal1 .clndr .clndr-table tr .day.event {
    background: #21213b !important;
}
@media (max-width: 991px) {
    .sidebar-mini.app-header.header .header-brand-img.desktop-logo {
        display: block;
    }
}
.cal1 .clndr .clndr-table tr .day.my-event {
    background: #21213b !important;
}
.cal1 .clndr .clndr-table tr .day.event:hover,
.cal1 .clndr .clndr-table tr .day.my-event:hover,
.cal1 .clndr .clndr-table tr .day.today,
.cal1 .clndr .clndr-table tr .day.my-today {
    color: #dedefd;
}
.main-chat-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.main-nav-line-chat .nav-link {
    color: #dedefd;
}
.main-chat-list .media.new {
    background-color: #2a2635;
}
.main-chat-list .media:hover {
    background-color: #2e2e4a;
}
.dark-mod .main-chat-list .media:focus {
    background-color: #2a2a46;
}
.main-chat-list .media + .media {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.main-nav-line-chat {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.main-chat-list .media.selected {
    background-color: #2a2a46;
}
.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: #22223d;
    color: #dedefd;
}
.chat-right .main-msg-wrapper:first-child:before {
    border-bottom: 8px solid #22223d;
}
.main-msg-wrapper {
    background-color: #22223d;
}
.chat-left .main-msg-wrapper:first-child:before {
    border-bottom: 8px solid #22223d;
}
.main-chat-time span {
    background: #2a2a46;
}
.main-chat-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #2a2635;
}
.main-msg-send {
    color: #ffff;
}
.alert-success {
    color: #09ad95;
    background-color: rgba(110, 179, 149, 0.1);
    border-color: rgba(110, 179, 149, 0.1);
}
.alert-danger {
    color: #f82649;
    background-color: rgba(255, 56, 43, 0.1);
    border-color: rgba(255, 56, 43, 0.1);
}
.alert-info {
    color: #6faedc;
    background-color: #526775;
    border-color: #2c526d;
}
.alert-warning {
    color: #d0be73;
    background-color: #615b44;
    border-color: #635e4b;
}
.alert-success .alert-link {
    color: #52851e;
}
.alert-info .alert-link {
    color: #b8dcf7;
}
.alert-warning .alert-link {
    color: #e8d998;
}
.alert-danger .alert-link {
    color: #b58281;
}
.irs-line-mid,
.irs-line-right,
.irs-line-left {
    background-color: #22223a;
}
.side-menu__item.active:hover,
.side-menu__item.active:focus {
    text-decoration: none;
    color: #ffffff;
    background: linear-gradient(to right, #9e88f5 0%, #2a2635 100%) !important;
    border-radius: 0 60px 60px 0;
    box-shadow: 0 7px 12px 0 rgba(95, 118, 232, 0.21);
}
.side-menu__item:hover .side-menu__icon {
    color: #dedefd !important;
}
.side-menu__item:hover .side-menu__label {
    color: #dedefd !important;
}
.side-menu__item:focus .side-menu__icon,
.side-menu__item:focus .side-menu__label {
    color: #dedefd !important;
}
.slide.is-expanded a {
    color: #dedefd;
}
.side-menu__item:hover {
    background: #2e2e4a !important;
}
.side-menu__item:focus {
    background: #2e2e4a !important;
}
.br-theme-fontawesome-stars .br-widget a:after {
    color: #25253e;
}
.br-theme-bars-1to10 .br-widget a.br-active,
.br-theme-bars-1to10 .br-widget a.br-selected {
    background-color: #2a2635;
}
.br-theme-bars-movie .br-widget a {
    background-color: #25253e;
}
.br-theme-bars-movie .br-widget a.br-active,
.br-theme-bars-movie .br-widget a.br-selected {
    background-color: #2a2635;
}
.br-theme-bars-square .br-widget a {
    border: 2px solid rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.08);
    color: #e2e2ea;
}
.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
    border: 2px solid #2a2635;
    color: #2a2635;
}
.br-theme-bars-pill .br-widget a {
    background-color: #25253e;
}
.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected {
    background-color: #2a2635;
    color: white;
}
.br-theme-bars-1to10 .br-widget a {
    background-color: #25253e;
}
.br-theme-bars-square .br-widget a {
    border: 2px solid #25253e;
    background-color: #2a2635;
    color: #e5e9f2;
}
.br-theme-bars-horizontal .br-widget a {
    background-color: #25253e;
}
.br-theme-bars-horizontal .br-widget a.br-active,
.br-theme-bars-horizontal .br-widget a.br-selected {
    background-color: #2a2635;
}
.vtimeline .timeline-wrapper .timeline-panel {
    background: #252542;
    -webkit-box-shadow: 0 5px 12px 0 #101329;
    box-shadow: 0 5px 12px 0 #101329;
}
.vtimeline .timeline-wrapper .timeline-panel:after {
    border-top: 14px solid transparent;
    border-left: 14px solid #252542;
    border-right: 0 solid #252542;
    border-bottom: 14px solid transparent;
}
.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}
.vtimeline .timeline-wrapper .timeline-panel:after {
    border-top: 14px solid transparent;
    border-left: 14px solid #252542;
    border-right: 0 solid #252542;
    border-bottom: 14px solid transparent;
}
.vtimeline .timeline-wrapper .timeline-badge {
    border: 2px solid #45455f;
}
.vtimeline:before {
    background-color: rgba(255, 255, 255, 0.1);
}
.timeline-wrapper-primary .timeline-panel:before {
    background: #2a2635;
}
.timeline-wrapper-primary .timeline-badge {
    background: #2a2635;
}
.main-content-label,
.card-table-two .card-title,
.card-dashboard-eight .card-title {
    color: #dedefd;
}
.tree li {
    color: #dedefd;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.tree li.branch {
    background: #22223d;
}
.tree li a {
    color: #dedefd;
}
.tree li.branch li {
    background: #2a2635;
}
.tree ul:before {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.tree ul li:before {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.btn-default {
    background: #dedefd;
    border-color: #c3c3cc;
}
.list-group-item,
.listorder,
.listunorder,
.listorder1,
.listunorder1 {
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.nav-item1 .nav-link {
    color: #dedefd;
}
.nav-item1 .nav-link.active {
    color: #2a2635;
}
.nav.nav-pills .nav-item .nav-link-icon {
    color: #dedefd;
}
.bg-light {
    background-color: #23223a !important;
}
.tooltip-static-demo,
.popover-static-demo {
    background-color: #23223a;
}
.tooltip-primary.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #2a2635 !important;
}
.tooltip-primary.bs-tooltip-auto[data-popper-placement^='top']
    .tooltip-arrow::before {
    border-top-color: #2a2635 !important;
}
.tooltip-primary.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^='bottom']
    .tooltip-arrow::before {
    border-bottom-color: #2a2635 !important;
}
.tooltip-primary.bs-tooltip-left .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^='left']
    .tooltip-arrow::before {
    border-left-color: #2a2635 !important;
}
.tooltip-primary.bs-tooltip-right .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^='right']
    .tooltip-arrow::before {
    border-right-color: #2a2635 !important;
}
.bg-white {
    background-color: #2a2635 !important;
}
.header-brand .header-brand-img.logo-3 {
    display: none;
}
.header-brand .header-brand-img.logo {
    display: block;
}
.footer .social ul li a {
    border: 1px solid #2a2635;
    background: #22223d;
}
.ff_fileupload_wrap .ff_fileupload_dropzone {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #2e2e4a;
}
.ff_fileupload_wrap .ff_fileupload_dropzone:hover {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.ff_fileupload_wrap .ff_fileupload_dropzone:focus,
.ff_fileupload_wrap .ff_fileupload_dropzone:active {
    background-color: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.ui-timepicker-wrapper {
    background: #23223a;
    border: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: 0 16px 18px 0 #0e0f2e;
}
.ui-timepicker-list li {
    color: rgba(255, 255, 255, 0.8);
}
.datepicker .datepicker-switch,
.datepicker td,
.datepicker th {
    color: #dedefd !important;
}
.datepicker-dropdown.datepicker-orient-top:after,
.datepicker-dropdown.datepicker-orient-top:before {
    border-top: 7px solid #2a2635;
}
.card-pay .tabs-menu li a {
    background: #232239;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.SumoSelect > .CaptionCont {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #2e2e4a;
}
.SumoSelect.disabled > .CaptionCont {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #22223c;
    border-radius: 0;
}
.sp-replacer {
    border: solid 1px #43435c;
    background: #2e2e4a;
    color: #fff;
}
.sp-replacer:hover,
.sp-replacer.sp-active {
    border-color: #45455f;
    color: #fff;
}
.datepicker-dropdown.datepicker-orient-top:after,
.datepicker-dropdown.datepicker-orient-top:before {
    border-top: 7px solid #3e3e50;
}
.datepicker-dropdown:after,
.datepicker-dropdown:before {
    border-bottom-color: #2e2e4a;
}
.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
    background: #21213c !important;
    color: #dedefd !important;
}
.transfer-double {
    background-color: #2a2635;
}
.transfer-double-content-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.transfer-double-content-tabs .tab-item-name.tab-active {
    background: #252336;
}
.transfer-double-content-left,
.transfer-double-content-right {
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.transfer-double-list-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.transfer-double-list-search-input {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #2a2635;
}
.checkbox-group label:before {
    background: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.transfer-double-content-param {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.transfer-double-selected-list-search-input {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #2a2635;
}
.btn-select-arrow {
    color: #dedefd;
    background: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.multi-wrapper {
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.multi-wrapper .search-input {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background: #2a2635;
}
.multi-wrapper .item-1 {
    color: #dedefd;
    background: #2a2635;
}
.multi-wrapper .selected-wrapper,
.multi-wrapper .item-group-1 .group-label {
    background: #23223a;
}
.iti input {
    background-color: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.12);
    color: #dedefd;
}
.iti input[type='tel'],
.iti input[type='text'] {
    background-color: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.12);
    color: #dedefd;
}
.iti__selected-flag {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: #2a2635;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.iti__country-list {
    background-color: #2a2635;
    border: 1px solid #43435d;
}
.iti__country.iti__highlight {
    background-color: #23223a;
}
.iti__divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.multi-wrapper .non-selected-wrapper {
    background: #44435a;
    border-right: 1px solid #2a2635;
}
.SumoSelect.open > .optWrapper {
    background: #2a2635;
}
.SumoSelect > .optWrapper {
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.SumoSelect > .optWrapper > .options li.opt {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.SumoSelect > .optWrapper > .options li.opt:hover {
    background-color: #262641;
}
.richText {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #2f2f4b !important;
}
.richText .richText-toolbar {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.ql-toolbar.ql-snow {
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.ql-toolbar.ql-snow .ql-picker-label {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #2f2f4b;
}
.ql-container.ql-snow {
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #2f2f4b;
    color: #dedefd;
}
.ql-snow.ql-toolbar button:last-child,
.ql-snow .ql-toolbar button:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.sw-theme-dots > ul.step-anchor {
    background: #2f2f4b;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.sw-theme-dots > ul.step-anchor:before {
    background-color: #3e3e63;
}
.sw-theme-dots > ul.step-anchor > li > a:before {
    background: #3e3e63;
}
.sw-theme-dots .step-content {
    background-color: #2f2f4b;
}
.sw-theme-dots .sw-toolbar {
    background: #2f2f4b;
}
.wizard {
    border: 1px solid #494963;
    background-color: #2f2f4b;
    border-radius: 3px;
}
.wizard > .content {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.wizard > .content > .title {
    color: #dedefd;
}
.wizard > .actions .disabled a {
    background-color: #4a4a5f;
    color: rgba(255, 255, 255, 0.62);
}
.wizard > .steps .current a .number,
.wizard > .steps .current a:active .number,
.wizard > .steps .current a:hover .number {
    background-color: #2a2635;
}
.wizard > .steps a .number,
.wizard > .steps a:active .number,
.wizard > .steps a:hover .number {
    background-color: #22223d;
}
.wizard.vertical > .content,
.wizard.vertical > .actions {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.wizard > .steps .done a .number,
.wizard > .steps .done a:active .number,
.wizard > .steps .done a:hover .number {
    background-color: #0dcd94;
}
.wizard > .steps a .number,
.wizard > .steps a:active .number,
.wizard > .steps a:hover .number {
    background-color: #22223d;
}
.cart .input-group-prepend > .btn,
.input-group-append > .btn,
.input-group-btn > .btn {
    border-color: #4a4a5f;
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    background: none;
}
.icons-list-item {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #2a2635;
}
.btn-white {
    color: #dedefd;
    background-color: #2a2635;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.user-social-detail .social-profile {
    background: #34334a;
}
.mail-inbox .icons {
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.list-group-transparent .list-group-item {
    border: 0 !important;
}
.acc-header a {
    background-color: #2f2f4b;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.acc-body {
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.input-group.input-indec .form-control {
    border-color: #4d4d61;
}
.app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}
.alert-default {
    background-color: #2e2e4a;
}
.side-menu__item:focus {
    background: #2e2e4a !important;
}
.side-menu__item:focus .side-menu__icon,
.side-menu__item:hover .side-menu__icon {
    color: #2a2635 !important;
}
.header .dropdown-menu .dropdown-item,
.responsive-navbar .dropdown-menu .dropdown-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #dedefd;
}
.header .dropdown-menu .dropdown-item:last-child,
.responsive-navbar .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0;
}
.header .notifications-menu h5,
.responsive-navbar .notifications-menu h5,
.header .message-menu h5,
.responsive-navbar .message-menu h5 {
    color: #dedefd;
}
.header .notifications-menu span,
.responsive-navbar .notifications-menu span,
.header .message-menu span,
.responsive-navbar .message-menu span {
    color: #9999bf;
}
.header .profile-1 .dropdown-item .dropdown-icon,
.responsive-navbar .profile-1 .dropdown-item .dropdown-icon {
    color: #dedefd;
}
.sidebar .user-pro-body img {
    background: #22223d;
}
.main-chat-list .media.new .media-contact-name span:first-child,
.main-chat-list .media-contact-name span:first-child,
.main-chat-list .media.selected .media-contact-name span:first-child {
    color: #dedefd;
}
.main-chat-list .main-img-user span {
    box-shadow: 0 0 0 2px #22223d;
}
.main-chat-list .media:hover,
.main-chat-list .media:focus {
    border-bottom: 1px solid transparent;
}
.main-chat-list .media-body p {
    color: #77778e;
}
.main-chat-list .media.new .media-body p {
    color: #77778e;
}
.main-chat-list .media.selected .media-body p {
    color: #77778e;
}
.main-chat-footer .form-control {
    background: none;
    border: 0;
}
#accordion .panel-default > .panel-heading {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.sp-container {
    background: #2a2635;
    box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.sp-picker-container {
    border-left: 0;
}
.SumoSelect > .CaptionCont {
    color: #9999bf;
}
.social-login {
    background: #2b2b48;
}
.table-inbox tr td i {
    color: #28273c;
}
.dark-mode.error-bg {
    background-image: url(../images/pngs/bg1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #22223d;
}
.accordion-button:not(.collapsed) {
    background: #272740;
}
.accordion-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #2e2e4b;
}
.accordion-button {
    color: #dedefd;
}
.accordion-item:not(:first-of-type) {
    border-top: 0;
}
.btn-close {
    color: #77778e;
}
.table > :not(:last-child) > :last-child > * {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}
.dropify-wrapper .dropify-message span.file-icon {
    color: #77778e;
}
.SumoSelect.open .search-txt {
    background: #2e2e4a;
    color: #77778e;
}
.SumoSelect .select-all {
    background-color: #2e2e4a;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.SumoSelect > .optWrapper > .MultiControls {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #2e2e4a;
}
.SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
    background-color: #393958;
}
.form-check-input {
    background: #2e2e4a;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-layout {
    display: none;
}
.light-layout {
    display: block;
}
.apexcharts-canvas line {
    stroke: rgba(255, 255, 255, 0.12);
}
.sidebar .dropdown-item h6 {
    color: #dedefd;
}
.bg-patterns {
    background: url('../images/patterns/1-dark.png');
}
.apexcharts-legend-text {
    color: #dedefd !important;
}
.apexcharts-tooltip.apexcharts-theme-light {
    background: #22223d;
    box-shadow: 2px 2px 6px -4px #22223d;
    border: 1px solid #2a2635;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #22223d;
    border-bottom: 1px solid #2a2635;
}
.list-group .list-contact-icons {
    background: #34334a;
}
.chat-left .main-msg-wrapper:first-child:before {
    border-bottom: 8px solid #22223d !important;
}
.main-chat-header .nav-link {
    color: #a8afc7;
}
.main-chat-msg-name small {
    color: #dedefd;
}
.breadcrumb-item1 a {
    color: #77778e;
}
.breadcrumb-item2 a {
    color: #77778e;
}
.cal1 .clndr .clndr-table .header-days .header-day {
    color: #fff;
    border: #45455f solid 1px;
}
.cal1 .clndr .clndr-table .header-days {
    background: #2a2635;
}
.cal1 .clndr .clndr-table tr .day.today,
.cal1 .clndr .clndr-table tr .day.my-today {
    background: #2a2635;
    color: #343a40;
}
.fc-theme-standard .fc-list-day-cushion .fc-list-day-text,
.fc-theme-standard .fc-list-day-cushion .fc-list-day-side-text {
    color: #dedef7 !important;
}
.btn-default-light {
    color: #292828;
    background: #f6f4fb54;
    border-color: #717087;
}
.panel-title1 a {
    background: #22223d !important;
}
.accordionjs .acc_section.acc_active > .acc_head {
    background: #22223d;
    color: #2a2635 !important;
}
