.skill-data-header-wrapper {
	display: flex;
	justify-content: space-between;
}
.skill-data-header {
	font-size: 32px;
	align-items: center;
}
table th, table td{
	border: 1px solid rgba(255, 255, 255, 0.1);
}
table th{
	color: #dedefd !important;
}
table td{
	color: #dedefd !important;
}
.react-bootstrap-table-sort-order.dropup .caret::after{
	right: 1em;
	content: "↓";
}
.react-bootstrap-table-sort-order .caret::after{
	right: 0.5em;
	content: "↑";
}
.table-bordered>:not(caption)>*>* {
    border-width: thin;
}
.table>:not(:first-child) {
    border: none;
}
#pageDropDown {
	color: #77778e !important;
	background-color: #32323e !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
#pageDropDown + ul li a {
	width: 100% !important;
}
#pageDropDown + ul.show {
	min-width: 4rem !important;
	color: red
}

/* Progress Bar */
/*--------- Width--------------*/

.w-1 {
	width: 10% !important;
}
.w-5 {
	width: 5% !important;
}
.w-10 {
	width: 10% !important;
}
.w-15 {
	width: 15% !important;
}
.w-20 {
	width: 20% !important;
}
.w-25 {
	width: 25% !important;
}
.w-30 {
	width: 30% !important;
}
.w-40 {
	width: 40% !important;
}
.w-45 {
	width: 45% !important;
}
.w-60 {
	width: 60% !important;
}
.w-45 {
	width: 45% !important;
}
.w-50 {
	width: 50% !important;
}
.w-65 {
	width: 65% !important;
}
.w-70 {
	width: 70% !important;
}
.w-75 {
	width: 75% !important;
}
.w-80 {
	width: 80% !important;
}
.w-100 {
	width: 100% !important;
}
.w-260 {
	width: 260px !important;
}
.w-337 {
	width: 337px !important;
}
.w-auto {
	width: auto !important;
}
.w-0 {
	width: 0 !important;
}
.w-1 {
	width: 0.25rem !important;
}
.w-2 {
	width: 0.5rem !important;
}
.w-3 {
	width: 0.75rem !important;
}
.w-4 {
	width: 1rem !important;
}
.w-5 {
	width: 1.5rem !important;
}
.w-6 {
	width: 2rem !important;
}
.w-7 {
	width: 3rem !important;
}
.w-8 {
	width: 4rem !important;
}
.w-9 {
	width: 6rem !important;
}
.w-90 {
	width: 90% !important;
}
.mw-100 {
	max-width: 100% !important;
}
.h-1 {
    height: 0.25rem !important;
}
.relative {
	position: relative;
}
/* .position-right {
	position: absolute;
	right: 0;
} */
.custom-dark-mode {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: transparent;
    background-color: #32323e !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    font-size: 12px;
    min-width: 118px;
    height: 28px;
    border-radius: 5px;
	transform: translate(0px, -6px);
}
.custom-dark-mode:hover {
	border-color: #5a51c7 !important;
}
.custom-dark-mode.disabled {
	color: gray;
	border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
