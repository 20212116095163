@media (max-width: 960px) {
    .MenuLinks-Container ul {
        visibility: hidden;
        display: block;
    }

    .MenuLinks-Container.menu-open ul {
        display: block;
    }


}

@media (min-width: 961px) {
    #App {
        display: none;
    }

}


.MenuLinks-Container {
    display: block;
    padding: 0px;
}

#App {
    font-family: sans-serif;
}

#page-wrap {
    text-align: center;

    overflow: auto;
}

.bm-item {
    display: inline-block;
    margin-left: 10px;
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
}

.bm-item:hover {
    color: white;
}


.bm-burger-button {
    position: absolute;
    width: 30px;
    height: 23px;
    left: 36px;
    top: 36px;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    margin-left: -4%;
}

.bm-menu-wrap {
    margin-left: -3%;
}
@media (max-width: 1540px){
.icon {
    left: 90%;
    position: absolute;
}
}

@media (min-width: 397px){
.icon {
    left: 92.8%;
    position: absolute;
}
}

/* .hamburger-react{
    visibility: hidden;
  } */
/* @media (min-width: 961px) {
    #burgermenu {
      display: none;
    }
  } */
/* {window.width > 960 && (
    <Hamburger
        className="burgermenu"
        size={20}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
    />
)} */
/* @media (min-width: 960px) {
    .burgermenu{
        display: none;
    }
  } */