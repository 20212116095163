.sr-only {
    display: none;
}
.filterinput{
    width: 20%;
    z-index: 1000;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    padding: 0 8px;
    min-width: 150px;
    margin-left: 10px;
    background-color: #282833;
    border: 1px solid #4e4d4d!important;
    color: white;
}

#text-filter-column-alert_text {
    width: 20%;
    z-index: 1000;
    height: 32px;
    border: 0;
    font-size: 14px;
    padding: 0 8px;
    min-width: 200px;
}

#text-filter-column-alert_text::-webkit-input-placeholder {
    color: rgb(151, 151, 145);
}

.form-control {
    background-color: #282833;
    border: 1px solid rgb(78, 77, 77) !important;
}

#alertTable .sortable {
    border: hidden;
}

#alertTable .id-custom-cell2 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #ffffff !important;
    border-color: transparent;
}

#alertTable th{
    border: hidden;
}

