.docs-content{
    padding-top: 80px;
}
.docs-content a {
    text-decoration:  none;
    color: #fff;
}
.docs-content a:hover{
    color: darkseagreen;
}
.docs-content img {
    margin-right: 15px;
}