.period-containor{
    /* display: flex;
    justify-content: space-between;
    flex-direction: column; */
    gap: 20px;
}

.period-1-containor{
    display: flex;
    gap:10px;
    justify-content: center;
    align-items: center;
}

.period-2-containor{
    display: flex;
    gap:10px;
    justify-content: center;
    align-items: center;
}

.company-title{
    color: white !important;
    font-size: 15px;
  }

 .company-name{
    margin-left: 10px;
 } 

.company-dropdown{
    display: flex;
    justify-content: center;
    align-items: center;
}  

.location-dropdown{
    display: flex;
    justify-content: left;
    align-items: left;
}  

.company-wise {
    margin: 0px 5px;
    padding: 0 !important; 
    padding-bottom: 5px !important;
    display: inline-block;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    border-bottom: none;
    transition: border-bottom 0.3s ease;
    white-space: nowrap;
    transition: opacity 0.3s ease;
}

.company-wise:hover {
    border-bottom: 1px solid cyan; 
}

.company-wise.active {
    border-bottom: 1px solid cyan;
    opacity: 1;
}

.company-wise.disabled {
    pointer-events: none; 
    opacity: 0.5; 
    cursor: not-allowed; 
}


@media (max-width: 1250px) {
    .company-dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
    }

    .period-containor{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.dropdown-item{
    width: 120px !important;
}

.dropdown-menu{
    --bs-dropdown-min-width: 120px !important;
    overflow-y: auto !important; 
}

.dropdown-menu::-webkit-scrollbar {
    display: none;
}